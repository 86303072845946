import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import './SinupPage.scss';
import axios from 'axios';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import './communication.css';
import config from './config.json';
// Create a styled component
const Container = styled.div`


  `;

const NotificationContainer = styled.div`
  position: absolute;
  top: 60px; /* Adjust the top position as needed */
  right: 20px; /* Adjust the right position as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1000;
`;

export const Communication = () => {

    const [searchData, setSearchData] = useState("");
    const [contacts, setContacts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const sessionID = localStorage.getItem('sessionID');
  const [hasSentMessage, setHasSentMessage] = useState({});
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchData({ ...searchData, [name]: value });
    };

    const addKeyValuePair = (key, value) => {
      setHasSentMessage(prevDictionary => ({
        ...prevDictionary,
        [key]: value,
      }));
    };
    

    const handleSearch = async (e) => {
        e.preventDefault();
        const messageStatus = {};

        try {
            const response = await axios.put(config.apiBaseUrl +`Communication?user=${sessionID}`, searchData);
            alert(JSON.parse(JSON.stringify(response.data.data)))
            setContacts(JSON.parse(JSON.stringify(response.data.data)))
            var statusarray={}
            JSON.parse(JSON.stringify(response.data.data)).forEach((contact) => {
             
              
              if (contact.status==="notvisited"){
                
               
                addKeyValuePair([contact.sender_email], true);
              }
              else{
                
                
                addKeyValuePair([contact.sender_email], false);
              }
             
            });
            

            


        } catch (error) {
            console.error('User registration failed:', error);
            alert(JSON.stringify(searchData))
            
        }
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

    const toComponentB = (mail) => {
        localStorage.setItem('contact',mail)
        navigate('/message');
    }


    
    
  
    return (
        <Container>
        
      <div>
        <div className="topnav">
          <div className="logo-container">
            <img src="images/logo.png" className="logo" alt="Logo" />
            <span className="site-name">Edumetrics</span>
          </div>

          <div className="dropdown">
            <a href="#" className="burger-icon">
              &#9776;
            </a>
            <div className="dropdown-content">
              <a href="/home">
                <img src="icons/icons8-home-50.png" />
                Home
              </a>
             <a href="/aboutus">
                <img src="icons/icons8-about-us-50.png" />
                About Us
              </a>
              <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                <img src="icons/blog.png" />
                Our Blog
              </a>
              <a href="/logout">
                <img src="icons/exit.png" />
                Log Out
              </a>
            </div>
          </div>
        </div>

        <form method="GET" onChange={handleChange}>
           <input type="text" name="query" placeholder="Enter your search query" />
           <input type="submit" value="Search" onClick={handleSearch} />
        </form>
        {console.log(hasSentMessage)}
        <div className="contact-list">
        {Array.isArray(contacts) ? (
            contacts.map((contact, index) => (
                <div className="contact">
                     <div className="contact-info">
                        <p>Email: {contact.sender_email}</p>
                        {console.log(contact.sender_email+" , "+hasSentMessage[contact.sender_email])}
                        <p> {hasSentMessage[contact.sender_email] && <span className="message-pop-symbol">New Message</span>}</p>
                        
                        <button onClick={() => { toComponentB(contact.sender_email) }}>Component B</button>
                     </div>
                </div>
            ))
                    ) : (
                            <div className="contact">
                                <div className="contact-info">
                                    <h2>sai sanjay</h2>
                                    <p>Email: saisanjaybandarupalli.a@gmail.com</p>
                                    <p>Phone: +16823764573</p>
                                    
                                </div>
                            </div>)}
      </div>

      <div className="footer">
        <div></div>
        <div className="uni_info">
          <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>

          <p>701 S Nedderman Dr,</p>
          <p>Arlington, TX 76019</p>
          <p>817-090-xxxx</p>

          <p>Privacy Statement | Contact</p>
          <p>@2023 University of Texas at Arlington</p>
        </div>

        <div className="contact-us">
          <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
          <img src="images/footer/email.png" alt="mail" />
          <img src="images/footer/linkedin.png" alt="linkedin" />
          <img src="images/footer/twitter (1).png" alt="twitter" />
          <img src="images/footer/instagram.png" alt="instagram" />
        </div>
                </div>
      </div>
    
    </Container>
  );
}
export default Communication;

