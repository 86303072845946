import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import ReportPage from '../components/Report';

const Container = styled.div`
`;

function App() {
    const [chatVisible, setChatVisible] = useState(false);
    const [reportVisibile, setReportVisible] = useState(false);
    const navigate = new useNavigate();
  const toggleChatContainer = () => {
    setChatVisible(!chatVisible);
    };
  const location = useLocation();

  const closeChat = () => {
    setChatVisible(false);
    };

    const handleCommunication = () => {
        navigate('/communication');
    }

    const handleProgram = () => {
      navigate('/pcc');
  }

  const handleReport = () => {
    setReportVisible(true)
    //navigate('/report');
}


  return (
    <Container>
    <div>
      <div className="topnav">
        <div className="logo-container">
            <img src="images/logo.png" className="logo" alt="Logo" />
            <span className="site-name">Edumetrics</span>
        </div>

        <div className="dropdown">
            <a href="#" className="burger-icon">
            &#9776;
            </a>
            <div className="dropdown-content">
            <a href="#">
                <img src="icons/icons8-home-50.png" />
                Home
            </a>
            <a href="/services">
                <img src="icons/icons8-services-50.png" />
                Services
            </a>
            <a href="/contact_us">
                <img src="icons/icons8-contact-us-50.png" />
                Contact Us
            </a>
            <a href="https://bxd3672.uta.cloud/wordpress_blog/">
              <img src="icons/blog.png" />
              Our Blog
            </a>
            <a href="/aboutus">
                <img src="icons/icons8-about-us-50.png" />
                About Us
            </a>
            <a href="/profile">
                <img src="icons/user.png" />
                Profile
            </a>
            <a href="/logout">
                <img src="icons/exit.png" />
                Log Out
            </a>
            </div>
        </div>
        </div>

      <main>
            
        <section className="communication">
        <button onClick={ handleCommunication }>Communication Tools</button>
            {/* Communication features and tools */}
        </section>
        <section className="guidance">
            <button onClick={ handleProgram }>Curriculum Management</button>
            
        </section>
        <section className="reports">
            <button onClick={ handleReport }>Student Report</button>
            
        </section>

    
      </main>
      {reportVisibile &&(
        <ReportPage/>
      )}
      <button id="toggleChatButton" onClick={toggleChatContainer}>
        Chat
      </button>
      {chatVisible && (
        <div className="chat-container">
          <a href="javascript:void(0)" className="closebtn" onClick={closeChat}>
            &times;
          </a>
          <div className="chat">
            <div className="chat-message bot">Hello! How can I assist you today?</div>
            <div className="chat-message user">Hi! I have a question.</div>
            {/* Add more messages here */}
          </div>
          <div className="user-input">
            <input type="text" id="userMessage" placeholder="Type your message..." />
            <button id="sendMessage">Send</button>
          </div>
        </div>
      )}

     
      <div className="footer">
      
        <div></div>
        {/* <img src="images/uta_logo.png" alt="UTA" className="uta_logo" /> */}
        <div className="uni_info">
            <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
            <p>701 S Nedderman Dr,</p>
            <p>Arlington, TX 76019</p>
            <p>817-090-xxxx</p>
            <p>Privacy Statement | Contact</p>
            <p>@2023 University of Texas at Arlington</p>
        </div>
        <div className="contact-us">
            <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
            <img src="images/footer/email.png" alt="mail" />
            <img src="images/footer/linkedin.png" alt="linkedin" />
            <img src="images/footer/twitter (1).png" alt="twitter" />
            <img src="images/footer/instagram.png" alt="instagram" />
        </div>
        

      </div>
    </div>
    </Container>
  );
}

export default App;
