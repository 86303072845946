import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  // Function to handle logout
  const handleLogout = () => {
    // Remove the sessionID from localStorage
    localStorage.removeItem('sessionID');
    localStorage.removeItem('role');
    // Redirect to the login page
    setTimeout(() => {
        // Redirect to the login page after the delay
        navigate('/');
      }, 2000)
  };

  // Automatically trigger logout when the component mounts
  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div>
      <h1>Logging out...</h1>
      <p>You will be redirected to the login page shortly.</p>
      {/* You can add additional content or a loading spinner here */}
    </div>
  );
};

export default LogoutPage;
