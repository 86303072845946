import React, { useEffect, useRef, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';

const ChartComponent = () => {
  const [barChartData, setBarChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);

  // Sample data for the charts
  const sampleBarData = {
    labels: ['Category 1', 'Category 2', 'Category 3'],
    datasets: [
      {
        label: 'Data Set 1',
        data: [10, 20, 15],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const samplePieData = {
    labels: ['Category 1', 'Category 2', 'Category 3'],
    datasets: [
      {
        data: [30, 40, 20],
        backgroundColor: ['red', 'green', 'blue'],
      },
    ],
  };

  useEffect(() => {
    setBarChartData(sampleBarData);
    setPieChartData(samplePieData);
  }, []);

  return (
    <div>
      {barChartData && (
        <div>
          <h2>Bar Chart</h2>
          <Bar data={barChartData} ref={barChartRef} />
        </div>
      )}
      {pieChartData && (
        <div>
          <h2>Pie Chart</h2>
          <Pie data={pieChartData} ref={pieChartRef} />
        </div>
      )}
    </div>
  );
};

export default ChartComponent;
