import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from './config.json';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
const Container = styled.div`

`

function Message() {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const sessionID = localStorage.getItem('sessionID');
    
    const contact = localStorage.getItem('contact')
    let count = 0;
    const fetchData = async () => {
        setMessages([]);
        axios.post(config.apiBaseUrl + 'handleMessage', { 'newMessage': 'loading data', 'contact': contact, 'user': sessionID }
          )
            .then((response) =>
            {
                let conversationData = response.data.data.data;

        if (conversationData === undefined) {
            //alert(response.data.data.data);
        } else {
            let updatedMessages = [...messages]; // Create a copy of the current state
            for (let i = 0; i < conversationData.length; i++) {
                if (sessionID === conversationData[i].sender_email) {
                    updatedMessages.push({ text: conversationData[i].message_content, sender: 'You' });
                } else {
                    updatedMessages.push({ text: conversationData[i].message_content, sender: conversationData[i].sender_email });
                }
            }

            setMessages(updatedMessages); // Update the state after the loop
            setNewMessage('');
           // alert(JSON.stringify(messages)); // This will not reflect the updated state immediately
        
          }
		  })
            .catch((error) => {
                alert("error");
            });
    };

    const sendMessage = async () => {
        if (newMessage.trim() !== '') {
            //const contact = location.state.contact
            //alert(location.state.contact)
            //alert(sessionID)
            const response = await axios.post(config.apiBaseUrl + 'handleMessage', { 'newMessage': newMessage, 'contact': contact, 'user': sessionID });
            //alert(JSON.stringify(response));
            const updatedMessages = [...messages, { text: newMessage, sender: 'You' }];
            setMessages(updatedMessages);
            setNewMessage('');
        }
    };

    const handleInputChange = (event) => {
        setNewMessage(event.target.value);
    };

   
    

    const messageStyle = {
        border: '1px solid #ccc',
        padding: '10px',
        height: '300px',
        overflowY: 'auto',
    };

    const sentMessageStyle = {
        backgroundColor: '#007bff',
        color: '#fff',
        padding: '5px',
        margin: '5px',
        borderRadius: '5px',
        textAlign: 'right',
    };

    const receivedMessageStyle = {
        backgroundColor: '#f2f2f2',
        padding: '5px',
        margin: '5px',
        borderRadius: '5px',
        textAlign: 'left',
    };

    const inputStyle = {
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginRight: '10px',
        flex: 1,
    };

    const buttonStyle = {
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    // const handleDeleteMessage = async (messageid) => {
           
    //         const response = await axios.delete(config.apiBaseUrl + `deleteMessage?meddageid=${messageid}`);
    //         alert(JSON.stringify(response));
    //         fetchData()
    //  };

     useEffect(() => {
        setMessages([])
        fetchData();
    }, []);
    
    return (
        <div>
            
            <h1>Messaging System</h1>
            <div style={messageStyle} className="message-container">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        style={message.sender === 'You' ? sentMessageStyle : receivedMessageStyle}
                        className="message"
                        //onClick={()=>{handleDeleteMessage(message.message_id)}}
                    >
                        {message.sender}: {message.text}
                        
                    </div>
                ))}
            </div>
            <div className="message-input">
                <input
                    type="text"
                    placeholder="Type a message..."
                    style={inputStyle}
                    value={newMessage}
                    onChange={handleInputChange}
                />
                <button style={buttonStyle} onClick={sendMessage}>
                    Send
                </button>
            </div>
            
        </div>
    );
}

export default Message;
