import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './Admin.css';
import config from './config.json';
import axios from 'axios';
import ProfileComponent from '../components/Profile';
import ChatComponent from '../components/chat';

import Coursemanagement from '../components/course-mangement';
const Container = styled.div`


`;

function AdminDashboard() {
    const [showProfileStudentComponent, setShowProfileStudentComponent] = useState(false);
    const [showProfileInstructorComponent, setShowProfileInstructorComponent] = useState(false);
    const [showProfileProgramCoordinatorComponent, setShowProfileProgramCoordinatorComponent] = useState(false);
    const [showProfileQaComponent, setShowQaProfileComponent] = useState(false);
    const [showProfileAdminComponent, setShowProfileAdminComponent] = useState(false);
    const [showProfileCourseComponent, setShowProfileCourseComponent] = useState(false);
const showComponent = (componentId) => {  
    /*const components = document.querySelectorAll('.component');
    components.forEach((component) => {
      component.style.display = 'none';
    });*/

    

   // const selectedComponent = document.getElementById(componentId);
    //selectedComponent.style.display = 'block';
    alert(componentId)

    switch (componentId) {
        case 'student-management':
            localStorage.setItem('usertype', 'student');
            setShowProfileStudentComponent(true); // Set the state to true
            setShowProfileInstructorComponent(false);
            setShowQaProfileComponent(false);
            setShowProfileCourseComponent(false);
            setShowProfileProgramCoordinatorComponent(false);
            setShowProfileAdminComponent(false);
            break;
        case 'instructor-management':
            localStorage.setItem('usertype', 'instructor');
            setShowProfileStudentComponent(false); // Set the state to true
            setShowProfileInstructorComponent(true);
            setShowQaProfileComponent(false);
            setShowProfileCourseComponent(false);
            setShowProfileProgramCoordinatorComponent(false);
            setShowProfileAdminComponent(false); // Set the state to true
            break;
        case 'qao-management':
            localStorage.setItem('usertype', 'qa');
            setShowProfileStudentComponent(false); // Set the state to true
            setShowProfileInstructorComponent(false);
            setShowQaProfileComponent(true);
            setShowProfileCourseComponent(false);
            setShowProfileProgramCoordinatorComponent(false);
            setShowProfileAdminComponent(false); // Set the state to true
            break;
        case 'course-management':
            localStorage.setItem('usertype', 'courses');
            setShowProfileStudentComponent(false); // Set the state to true
            setShowProfileInstructorComponent(false);
            setShowQaProfileComponent(false);
            setShowProfileCourseComponent(true);
            setShowProfileProgramCoordinatorComponent(false);
            setShowProfileAdminComponent(false); // Set the state to true
            break;
        case 'program-coordinator-management':
            localStorage.setItem('usertype', 'pc');
            setShowProfileStudentComponent(false); // Set the state to true
            setShowProfileInstructorComponent(false);
            setShowQaProfileComponent(false);
            setShowProfileCourseComponent(false);
            setShowProfileProgramCoordinatorComponent(true);
            setShowProfileAdminComponent(false); // Set the state to true
            break;
        
        default:
            
            break;
    }

    alert(componentId);

    };



    const sessionID = localStorage.getItem('sessionID')
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        //alert(usertype)

        localStorage.removeItem('usertype')

    }, []);

    
return (
    <Container>
    <div className="admin-dashboard">
      <div className="topnav">
        <button className="toggle-sidebar-button" >&#9776;</button>
        <div className="logo-container">
          <img src="images/logo.png" className="logo" alt="Logo" />
          <span className="site-name">Edumetrics</span>
        </div>
        <div className="dropdown">
        <a href="#" className="burger-icon">
          &#9776;
        </a>
        <div className="dropdown-content">
          <a href="/home">
            <img src="icons/icons8-home-50.png" /> Home
          </a>
          <a href="/profile">
            <img src="icons/user.png" alt="User Icon" />
               Profile
          </a>
          <a href="/communication">
                              Communication Tools
                               </a>
          <a href="https://bxd3672.uta.cloud/wordpress_blog/">
            <img src="icons/blog.png" />
            Our Blog
          </a>
          <a href="/logout">
            <img src="icons/exit.png" /> Log Out
          </a>
        </div>
      </div>
      </div>

      <div className="container">
        <div className="sidebar">
            <div className="menu-item" onClick={() => showComponent('student-management')}>
            <img src="icons/icons8-student-50.png" className="sidebar-icons" alt="Student Management" />
            Student Management
        </div>

        <div className="menu-item" onClick={() => showComponent('instructor-management')}>
            <img src="icons/icons8-training-50.png" className="sidebar-icons" alt="Instructor Management" />
            Instructor Management
        </div>

        <div className="menu-item" onClick={() => showComponent('qao-management')}>
            <img src="icons/icons8-quality-control-64.png" className="sidebar-icons" alt="QAO Management" />
            QAO Management
        </div>

        <div className="menu-item" onClick={() => showComponent('program-coordinator-management')}>
            <img src="icons/icons8-manager-66.png" className="sidebar-icons" alt="Program Coordinator Management" />
            Program Coordinator Management
        </div>

        <div className="menu-item" onClick={() => showComponent('course-management')}>
            <img src="icons/icons8-book-50.png" className="sidebar-icons" alt="Course Management" />
            Course Management
        </div>
                    <div>
                        <ChatComponent />
                    </div>
        
        </div>

        <div className="content">
          <h1>Welcome to admin dashboard!!!</h1>

          
                    {showProfileStudentComponent && (
                        <div className="component" id="student-management">
                            <h2>Student Management</h2>

                            <ProfileComponent />
                        </div>
                    )}
        
                    {showProfileInstructorComponent && (
                        <div className="component" id="instructor-management">


                            <ProfileComponent />
                        </div>
                    )}
                    {showProfileQaComponent && (
                        <div className="component" id="qao-management">
                            <h2>QAO Management</h2>

                            <ProfileComponent />

                        </div>
                    )}

                    
                    {showProfileProgramCoordinatorComponent && (
                        <div className="component" id="program-coordinator-management">
                            <h2>Course Management</h2>

                            <ProfileComponent />

                        </div>
                    )}
                    {showProfileCourseComponent && (
                        <div className="component" id="course-management">
                            <h2>Course Management</h2>

                            <Coursemanagement />

                        </div>
                    )}
                </div>
                
            </div>


      <div className="footer">
        <img src="images/uta_logo.png" alt="UTA" className="uta_logo" />
        <div className="uni_info">
          <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
          <p>701 S Nedderman Dr,</p>
          <p>Arlington, TX 76019</p>
          <p>817-090-xxxx</p>
          <p>Privacy Statement | Contact</p>
          <p>@2023 University of Texas at Arlington</p>
        </div>
        <div className="contact-us">
          <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
          <img src="images/footer/email.png" alt="mail" />
          <img src="images/footer/linkedin.png" alt="linkedin" />
          <img src="images/footer/twitter (1).png" alt="twitter" />
          <img src="images/footer/instagram.png" alt="instagram" />
        </div>
      </div>
    </div>
    </Container>
  );
}

export default AdminDashboard;
