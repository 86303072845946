import React, { useState } from 'react';

import './StudentPage.css';
import './Assessments.css';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
//import config from './config.json';
const Container = styled.div`
`;

export const AssessmentsTable = () => {
    
    const location = useLocation();
  const  assessments = location.state.assignments;
  const [isOpen, setIsOpen] = useState(false);
  
    const sessionID=localStorage.getItem('sessionID');
    

    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

   return (
        <Container>
            <div>
                <div className="topnav">
                    <div className="logo-container">
                        <img src="images/logo.png" className="logo" alt="Logo" />
                        <span className="site-name">Edumetrics</span>
                    </div>

                    <div className="dropdown">
                        <a href="#" className="burger-icon" onClick={toggleMenu}>
                            &#9776;
                        </a>
                        {isOpen && (
                            <div className="dropdown-content">
                                <a href="/student_profile">
                                    <img src="icons/user.png" alt="User Icon" />
                                    Student Profile
                                </a>
                                <a href="/student">
                                    <img src="icons/user.png" alt="User Icon" />
                                    Courses
                                </a>
                                <a href="#">
                                    <img src="icons/test.png" alt="Test Icon" />
                                    Grade Info
                              </a>

                                <a href="/home">
                                  <img src="icons/icons8-home-50.png" alt="Scholarship Icon" />
                                    Home
                                </a>
                                <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                                  <img src="icons/blog.png" />
                                  Our Blog
                                </a>
                                <a href="/logout">
                                    <img src="icons/exit.png" alt="Exit Icon" />
                                    Log Out
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                <div className="assessments-table">
      <table>
        <thead>
          <tr>
            <th>Assignment ID</th>
            <th>Assignment Name</th>
            <th>Course ID</th>
            <th>Grade</th>
            <th>Status</th>
            <th>Due Date</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {assessments.map((assessment) => (
            <tr key={assessment.assignment_id}>
              <td>{assessment.assignment_id}</td>
              <td>{assessment.assignment_name}</td>
              <td>{assessment.course_id}</td>
              <td>{assessment.grade}</td>
              <td>{assessment.status}</td>
              <td>{assessment.due_date}</td>
              <td>{assessment.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                <div className="chat">
                    <input type="checkbox" id="click" />
                    <label htmlFor="click">Chat</label>
                    <i className="fab fa-facebook-messenger"></i>
                    <i className="fas fa-times"></i>
                    <div className="wrapper">
                        <div className="head-text">Chat</div>
                        <div className="chat-box">
                            <div className="desc-text">Please fill out the form below to start chatting.</div>
                            <div className="field">
                                <input type="text" placeholder="Your Name" required />
                            </div>
                            <div className="field">
                                <input type="email" placeholder="Email Address" required />
                            </div>
                            <div className="field textarea">
                                <textarea cols="30" rows="10" placeholder="Explain your queries.." required></textarea>
                            </div>
                            <div className="field">
                                <button type="submit">Start Chat</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chat-container">
                    <button id="closeChatButton">X</button>
                    <div className="chat">
                        <div className="chat-message bot">Hello! How can I assist you today?</div>
                        <div className="chat-message user">Hi! I have a question.</div>
                        {/* Add more messages here */}
                    </div>
                    <div className="user-input">
                        <input type="text" id="userMessage" placeholder="Type your message..." />
                        <button id="sendMessage">Send</button>
                    </div>
                </div>

                <div className="footer">
                    <div></div>
                    <div className="uni_info">
                        <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                        <p>701 S Nedderman Dr,</p>
                        <p>Arlington, TX 76019</p>
                        <p>817-090-xxxx</p>
                        <p>Privacy Statement | Contact</p>
                        <p>@2023 University of Texas at Arlington</p>
                    </div>
                    <div className="contact-us">
                        <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                        <img src="images/footer/email.png" alt="mail" />
                        <img src="images/footer/linkedin.png" alt="linkedin" />
                        <img src="images/footer/twitter (1).png" alt="twitter" />
                        <img src="images/footer/instagram.png" alt="instagram" />
                    </div>
                </div>
            </div>
            </Container>
        );
}
export default AssessmentsTable;