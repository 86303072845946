import React, { useState,useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './StudentPage.css';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Chart from 'chart.js/auto';
import config from './config.json';

// Create a styled component
const Container = styled.div`




`;


function QualityAssuranceOfficerDashboard() {
  // Define a function to show components
    
    //const [courseName, setCourseName] = useState('');
    const [isContentVisible, setContentVisible] = useState(false);
    const [exams, setExams] = useState([]);
    //const [courseNames, setCourseNames] = useState([]);
    const [barChart, setBarChart] = useState();
    const navigate = useNavigate();
    const toggleContent = (e) => {
        e.preventDefault();
        setContentVisible(!isContentVisible);
        recommendation();
    };


    const [recommendations, setRecommendations] = useState({});


    


   

    const handleButtonClick = (index, enrollment_id) => {

        updaterecommendation(recommendations[index], enrollment_id);
    };


    const updaterecommendation = async (recommendation,enrollment_id) => {
        try {
            const message = "updateRecommendation"
            const response = await axios.get(config.apiBaseUrl + `qaController.php?message=${message}&enrollment_id=${enrollment_id}&recommendation=${recommendation}`);
            alert(JSON.stringify(response.data.data))
            





        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const handleAnalytics = async (data) => {

        try {
            
            
            if (barChart) {
               
                barChart.destroy();
            }
           
            const labels = [];
            const dataValues = [];

            
            labels.push(data.courseName);
            dataValues.push(data.grade);
            
                
            var barChartData = {
                labels: labels,
                datasets: [{
                    label: data.name,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    data: dataValues
                }]
            };
            




            var barChartCanvas = document.getElementById('barChart');
             setBarChart(new Chart(barChartCanvas, {
                type: 'bar',
                data: barChartData,
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }));
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

   
  const showComponent = (componentId) => {  
    const components = document.querySelectorAll('.component');
    components.forEach((component) => {
      component.style.display = 'none';
    });

    
    const selectedComponent = document.getElementById(componentId);
    selectedComponent.style.display = 'block';
  };

  

   const recommendation = async () => {
    try {  const message="studentDetails"
        const response = await axios.get(config.apiBaseUrl + `qaController.php?message=${message}`);
        

        const data = JSON.parse(JSON.stringify(response.data.data));
    
        setExams(data)
        } catch (error) {
           console.error('Error fetching programs:', error);
       }
    };

    const handleRecommendationChange = (index, value) => {
        setRecommendations((prevRecommendations) => ({
            ...prevRecommendations,
            [index]: value,
        }));
    };

   
    
   

  return (
    <Container>
    <div >
      <div className="topnav">
        <button className="toggle-sidebar-button">&#9776;</button>
        <div className="logo-container">
          <img src="images/logo.png" className="logo" alt="Logo" />
          <span className="site-name">Edumetrics</span>
        </div>
        <div className="dropdown">
          <a href="#" className="burger-icon">
            &#9776;
          </a>
          <div className="dropdown-content">
            <a href="/qa">
              <img src="icons/icons8-home-50.png" alt="Home" />
              Home
            </a>
            <a href="/services">
              <img src="icons/icons8-services-50.png" alt="Services" />
              Services
            </a>
            <a href="/contact_us">
              <img src="icons/icons8-contact-us-50.png" alt="Contact Us" />
              Contact Us
            </a>
            <a href="/aboutus">
              <img src="icons/icons8-about-us-50.png" alt="About Us" />
              About Us
            </a>
            <a href="https://bxd3672.uta.cloud/wordpress_blog/">
              <img src="icons/blog.png" />
              Our Blog
            </a>
            <a href="/communication">
                              Communication Tools
                               </a>
            <a href="#">
              <img src="icons/user.png" alt="Profile" />
              Profile
            </a>
            <a href="/logout">
              <img src="icons/exit.png" alt="Log Out" />
              Log Out
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="sidebar">
          <div className="menu-item" onClick={() => showComponent('program-objective')}>
            <img src="icons/icons8-manager-66.png" className="sidebar-icons" alt="Program Objective" />
            Program Objective
          </div>
          <div className="menu-item" onClick={() => showComponent('recommendation')}>
            <img src="icons/icons8-training-50.png" className="sidebar-icons" alt="Recommendation" />
            Recommendation
          </div>
          <div className="menu-item" onClick={() => showComponent('report-generation')}>
            <img src="icons/icons8-quality-control-64.png" className="sidebar-icons" alt="Report Generation" />
            Report Generation
          </div>
          <div className="menu-item" onClick={() => showComponent('policy')}>
            <img src="icons/icons8-quality-control-64.png" className="sidebar-icons" alt="Report Generation" />
            Policies & Guidelines
          </div>
          
        </div>

        <div className="content">
          <h1>Quality Assurance Officer Dashboard</h1>
                      
          <div className="component" id="program-objective">
            <h2>Program Objective</h2>

            <div className="course-selection">
              <label htmlFor="course-dropdown">Department:</label>
              <select id="course-dropdown">
                <option value="course-1">Computer Science</option>
                <option value="course-2">Electronics</option>
              </select>
            </div>

            <div className="course-list">
              <h3>Course List</h3>
              <table className="courses-table">
                <thead>
                  <tr>
                    <th>Course ID</th>
                    <th>Course Name</th>
                    <th>Instructor</th>
                    <th>Status(Completed/Inprogress)</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>12783</td>
                    <td>Real Time Embedded Systems</td>
                    <td>George Bush</td>
                    <td>70%</td>
                    <td>
                      <button className="action-button" >
                        View Reviews
                      </button>
                      <button className="action-button" >
                        Give Feedback
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>26432</td>
                    <td>Web Analytics</td>
                    <td>Bret Lee</td>
                    <td>84%</td>
                    <td>
                      <button className="action-button" >
                        View Reviews
                      </button>
                      <button className="action-button" >
                        Give Feedback
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="courses-details">
              <h3>Course Details</h3>

              <div className="course-info">
                <h4>Course ID: 12783</h4>
                <p>
                  <strong>Course Name:</strong>Real Time Embedded Systems
                </p>
                <p>
                  <strong>Instructor:</strong>George Bush
                </p>
                <p>
                  <strong>Status:</strong> 70%
                </p>
              </div>
            </div>
          </div>

                      <div className="component" id="recommendation">
                          <div class="colorful-main">
                              <section class="report-card colorful-card">
                                  <h2>Course Performance Report</h2>
                                  <p>Generate a report that shows the performance metrics of all courses.</p>
                                  <button onClick={toggleContent} class="generate-button colorful-button">Generate Report</button>

                              </section>
                              <div style={{ display: isContentVisible ? 'block' : 'none' }} className="instructor-performance-data">
                                  <h1>Student Performance Table</h1>
                                  <table className='coordinator-table'>
                                      <tbody><tr>

                                          <th>Name</th>
                                         
                                          <th>courseName</th>
                                          <th>Analytics</th>

                                      </tr>
                                          {exams.map((exam, index) => (
                                              <tr>
                                                  <td>{exam.userName}</td>
                                                  <td>{exam.courseName}</td>
                                                  <td>{exam.avg_grade}</td>
                                                  <td><button type="button" onClick={() => handleAnalytics({ 'courseName': exam.courseName, 'grade': exam.avg_grade, 'name': exam.userName })} className="generate-button colorful-button">
                                                      Analytics
                                                  </button></td>
                                                  <input type="textarea" name={`recommendation-${index}`} value={ recommendations[index] || '' } onChange={(e) => handleRecommendationChange(index,e.target.value)} />
                                                  <button type="button" onClick={() => handleButtonClick(index, exam.enrollment_id)} className="generate-button colorful-button">
                                                      Submit
                                                  </button>

                                              </tr>
                                          ))}
                                          {/* Add more rows for additional students */}
                                      </tbody></table>
                                  <div className="chart-container-coordinator">
                                      {/* Bar Chart Container */}
                                      <div className="chart">
                                          <canvas id="barChart" />
                                      </div>
                                      {/* Pie Chart Container */}
                                      <div className="chart">
                                          <canvas id="pieChart" />
                                      </div>
                                      
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                   

                      

          <div className="component" id="report-generation">
            <h2>Report Generation</h2>

            <div className="course-selection">
              <label htmlFor="course-dropdown">Options:</label>
              <select id="course-dropdown" >
                <option value="dropdown-1">Departments</option>
                <option value="dropdown-2">Courses</option>
                <option value="dropdown-3">Students</option>
                <option value="dropdown-4">Instructors</option>
              </select>
            </div>

            <div className="course-list">
              <h3>Department List</h3>
              <table className="courses-table">
                <thead>
                  <tr>
                    <th>Department ID</th>
                    <th>Department Name</th>
                    <th>Program Coordinator</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>45678</td>
                    <td>Computer Science</td>
                    <td>David Lee</td>
                    <td>Stevens Hall</td>
                  </tr>
                  <tr>
                    <td>45556</td>
                    <td>Electronics</td>
                    <td>Falguni Pathak</td>
                    <td>Bishops Gate</td>
                  </tr>
                </tbody>
              </table>
            </div>

            

            

            

            <div className="form-group">
              <button type="submit">Report to Excel</button>
            </div>
          </div>
          <div className="component" id="policy">
            
            <h3>Quality Assurance Policies and Processes </h3>
            <p>
              At our University, we are committed to providing an exceptional and reliable student management platform that empowers both students and faculty. 
              </p>
              <p>Our Quality Assurance Policies and Processes are designed to ensure that our website consistently delivers the best user experience and maintains the highest standards of quality. These policies and processes are guided by the following principles:
            </p>
            <p>
             <h4> User-Centric Approach:</h4>

              We put our users at the center of everything we do. Our policies and processes are designed to meet the needs and expectations of our students, instructors, and administrators.
              </p>
              <p>
              <h4>Continuous Improvement:</h4>

              We constantly seek ways to enhance our website's functionality and user-friendliness. Regular feedback from users and internal assessments drive our improvements.
              </p>
              <p>
              <h4>Data Security and Privacy:</h4>

              We prioritize the security and privacy of user data. Our processes comply with all applicable data protection regulations, and we regularly update security protocols.
              </p>
              <p>
              <h4>Accessibility and Inclusivity:</h4>
              Our website is designed to be accessible to all users, including those with disabilities. We are committed to ensuring equal access and usability for everyone.
              </p>
              <p>
              <h4>Content Accuracy and Relevance:</h4>

              All information provided on our website is accurate and up-to-date. We ensure that our content remains relevant and beneficial to users.
              </p>
              <p>
              <h4>Timely Support and Assistance:</h4>

              We offer prompt assistance to address user queries and issues. Our support team is readily available to provide help whenever needed.
              </p>
              <p>
              
              <h4>Risk Management:</h4>

              We assess and manage risks related to website functionality, security, and data privacy. Proactive risk mitigation is part of our ongoing process.
              </p>
              <p>
              <h4>Compliance:</h4>

              We adhere to all relevant laws and regulations governing student data, online education, and website operations.
              </p>
              <p>



              <h4>User Feedback Collection:</h4>

              We actively gather feedback from users through surveys, focus groups, and direct communication.
              </p>
              <p>
              Regular Testing:

              Our Quality Assurance team conducts regular testing to ensure that all features and functions operate correctly.
              </p>
              <p>
              Security Audits:

              Periodic security audits help identify and address vulnerabilities to protect user data.
              </p>
              <p>
              Accessibility Checks:

              We run regular accessibility checks to guarantee a user-friendly experience for all.
              </p>
              <p>
              Content Review:

              Content is reviewed regularly to ensure its accuracy and relevance.
              </p>
              <p>
              Incident Response:

              In the event of any incidents or outages, we follow predefined procedures to minimize disruption and quickly restore services.
              </p>
              <p>
              Data Protection:

              We implement strict data protection measures, including encryption, access controls, and data backup procedures.
              </p>
              <p>
              Compliance Monitoring:

              We continuously monitor changes in regulations and ensure compliance with all applicable laws.
              By adhering to these Quality Assurance Policies and Processes, we aim to provide an exceptional online platform for student management that promotes learning and collaboration. Our commitment to quality ensures that [Your University Name] remains a trusted and reliable resource for our educational community.


            </p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="uta-logo-container">
          <img src="images/uta_logo.png" alt="UTA" className="uta_logo" />
        </div>
        <div className="footer-content">
          <div className="uni_info">
            <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
            <p>701 S Nedderman Dr,</p>
            <p>Arlington, TX 76019</p>
            <p>817-090-xxxx</p>
            <p>Privacy Statement | Contact</p>
            <p>@2023 University of Texas at Arlington</p>
          </div>
          <div className="contact-us">
            <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
            <img src="images/footer/email.png" alt="mail" />
            <img src="images/footer/linkedin.png" alt="linkedin" />
            <img src="images/footer/twitter (1).png" alt="twitter" />
            <img src="images/footer/instagram.png" alt="instagram" />
          </div>
        </div>
      </div>

      <div className="chat">
        <input type="checkbox" id="click" />
        <label htmlFor="click">Chat</label>
        <i className="fab fa-facebook-messenger"></i>
        <i className="fas fa-times"></i>
        <div className="wrapper">
          <div className="head-text">Chat</div>
          <div className="chat-box">
            <div className="desc-text">Please fill out the form below to start chatting.</div>
            <div className="field">
              <input type="text" placeholder="Your Name" required />
            </div>
            <div className="field">
              <input type="email" placeholder="Email Address" required />
            </div>
            <div className="field textarea">
              <textarea cols="30" rows="10" placeholder="Explain your queries.." required></textarea>
            </div>
            <div className="field">
              <button type="submit">Start Chat</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Container>
  );
}

export default QualityAssuranceOfficerDashboard;
