import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SinupPage.css';
import config from './config.json';
import axios from 'axios';
import ChatComponent from '../components/chat';

export const SinupPage = () => {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        password: '',
        mobileNumber: '',
        rolename: '',
    });




   
    const [errors, setErrors] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const validateField = (name, value) => {
        let error = '';

        if (!value) {
            error = `${name} is required`;
        } else {
            switch (name) {
                case 'email':
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        error = 'Invalid email address';
                    }
                    break;
                case 'password':
                    if (value.length < 8) {
                        error = 'Password should be at least 8 characters';
                    }
                    break;
                case 'mobileNumber':
                    if (!/^\d{10}$/.test(value)) {
                        error = 'Invalid mobile number';
                    }
                    break;
                case 'rolename':
                    if (!(value === "student" || value === "instructor" || value === "programcoordinator" || value === "admin" || value === "qaofficer")) {
                        error = 'Invalid Rolename';
                     }
                    break;
                default:
                    break;
            }
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    /*const handleInputChange =  (e) => {
        const name = e.target.name;
        const value = e.target.value;

        formData.set(name, value);
        if (name !== 'firstname' && name !== 'lastname') {
            validateField(name, value);
        }
    };*/

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name !== 'firstname' && name !== 'lastname') {
            validateField(name, value);
        }
    };

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert(formData)
        await axios
            .post(config.apiBaseUrl + 'sinup', formData)
            .then((response) => {
                alert(JSON.parse(JSON.stringify(response)).message)


            })
            .catch((error) => {
                alert(JSON.parse(JSON.stringify(error)).message);
            });
};
   

    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

        return (
            
            
            <div>
                <div className="topnav">
                    <div className="logo-container">
                        <img src="images/logo.png" className="logo" alt="Logo" />
                        <span className="site-name">Edumetrics</span>
                    </div>
                    <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
                        <ChatComponent/>
                    </div>

                    <div className="dropdown">
                        <a href="#" className="burger-icon" onClick={toggleMenu}>
                            &#9776;
                        </a>
                        {isOpen && (
                            <div className="dropdown-content">
                                
                                
                                <a href="#">
                                    <img src="icons/icons8-home-50.png" alt="Scholarship Icon" />
                                    Home
                                </a>
                                <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                                    <img src="icons/icons8-blog-50.png" />
                                    Our Blog
                                </a>
                                <a href="/">
                                    <img src="icons/exit.png" />Log IN
                                </a>
                                
                                
                            </div>
                        )}
                    </div>
                </div>

                <div className="container_sinup">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstname">Firstname:</label>
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                
                                onChange={handleInputChange}
                                
                            />
                            {/* No need to display errors for firstname */}
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastname">Lastname:</label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                
                                onChange={handleInputChange}
                                
                            />
                            {/* No need to display errors for lastname */}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                onChange={handleInputChange}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                onChange={handleInputChange}
                            />
                            {errors.password && (
                                <span className="error">{errors.password}</span>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="mobileNumber">Mobile Number:</label>
                            <input
                                type="tel"
                                id="mobileNumber"
                                name="mobileNumber"
                                onChange={handleInputChange}
                            />
                            {errors.mobileNumber && (
                                <span className="error">{errors.mobileNumber}</span>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="rolename">Role Name:</label>
                            <select
                                name="rolename"
                                id="rolename"
                                onChange={handleInputChange}
                                value={formData.rolename}
                            >
                                <option value="">Select role</option>
                                <option value="student">Student</option>
                                <option value="instructor">Instructor</option>
                                <option value="programcoordinator">Program Coordinator</option>
                                <option value="admin">Admin</option>
                                <option value="qaofficer">QA Officer</option>
                            </select>
                            {errors.rolename && (
                                <span className="error">{errors.rolename}</span>
                            )}
                        </div>

                        <div className="form-group">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>

                <div className="footer">
                    <div></div>
                    <div className="uni_info">
                        <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                        <p>701 S Nedderman Dr,</p>
                        <p>Arlington, TX 76019</p>
                        <p>817-090-xxxx</p>
                        <p>Privacy Statement | Contact</p>
                        <p>@2023 University of Texas at Arlington</p>
                    </div>

                    <div className="contact-us">
                        <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                        <img src="images/footer/email.png" alt="mail" />
                        <img src="images/footer/linkedin.png" alt="linkedin" />
                        <img src="images/footer/twitter (1).png" alt="twitter" />
                        <img src="images/footer/instagram.png" alt="instagram" />
                    </div>
                </div>
            </div>

                                
           
        );







}

export default SinupPage;