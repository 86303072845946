import React, {useEffect,useState}from 'react';

 import './StudentProfile.css';
 import config from './config.json';
import axios from 'axios';



import styled from 'styled-components';

// Create a styled component
const Container = styled.div`


`;



function StudentProfile() {
//const [user,setUser] = useState({});
const sessionID = localStorage.getItem('sessionID')
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '', 
});

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};

const getProfileData = async () => {
  
  const message='profile'

  
 await axios.get(config.apiBaseUrl+`profile?message=${message}&email=${sessionID}`)
      .then((response) =>
      {   
          
          alert(JSON.parse(JSON.stringify(response.data.data))[0]);
          
          setFormData(JSON.parse(JSON.stringify(response.data.data))[0])
      })
      .catch((error) => {
          alert(JSON.stringify(formData));
      });
}

const editProfileData = async (e) => {
  e.preventDefault();
  const message='editprofile'

  
    await axios.put(config.apiBaseUrl + `EditProfile?email=${sessionID}`,formData)
      .then((response) =>
      {   
          
          alert(JSON.parse(JSON.stringify(response.data.data))[0]);
          
          setFormData(JSON.parse(JSON.stringify(response.data.data))[0])
      })
      .catch((error) => {
          alert(JSON.stringify(formData));
      });
}

  useEffect(() => {
       
    
  
    getProfileData();
    
  }, []);

  console.log(formData.first_name);
  return (
    <Container>
    <div >
      <div className="topnav">
        <div className="logo-container">
          <img src="images/logo.png" className="logo" alt="Logo" />
          <span className="site-name">Edumetrics</span>
        </div>

        <div className="dropdown">
          <a href="#" className="burger-icon">
            &#9776;
          </a>
          <div className="dropdown-content">
            
            
            <a href="https://bxd3672.uta.cloud/wordpress_blog/">
              <img src="icons/blog.png" />
              Our Blog
            </a>
            
            
            <a href="/logout">
              <img src="icons/exit.png" alt="Exit" />
              Log Out
            </a>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '100px', marginBottom: '100px' }}>
        <div className="prof_container">
          <form id="profile-form" >
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input type="text" id="firstName" name="first_name" value={formData.first_name} required onChange={handleChange}/>
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input type="text" id="lastName" name="last_name" value={formData.last_name} required onChange={handleChange}/>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} required onChange={handleChange}/>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="mobile_number" value={formData.mobile_number} required onChange={handleChange}/>
            </div>
            <div className="form-group">
              <button type="button" id="edit-button" onClick={editProfileData}>
                Edit Profile
              </button>
            </div>
          </form>
        </div>
      </div>

      <button id="toggleChatButton">Chat</button>

      <div className="chat-container">
        <button id="closeChatButton">X</button>
        <div className="chat">
          <div className="chat-message bot">Hello! How can I assist you today?</div>
          <div className="chat-message user">Hi! I have a question.</div>
          {/* Add more messages here */}
        </div>
        <div className="user-input">
          <input type="text" id="userMessage" placeholder="Type your message..." />
          <button id="sendMessage">Send</button>
        </div>
      </div>

      <div className="footer">
        <div></div>
        <img src="images/uta_logo.png" alt="UTA" className="uta_logo" />
        <div className="uni_info">
          <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
          <p>701 S Nedderman Dr,</p>
          <p>Arlington, TX 76019</p>
          <p>817-090-xxxx</p>
          <p>Privacy Statement | Contact</p>
          <p>@2023 University of Texas at Arlington</p>
        </div>

        <div className="contact-us">
          <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
          <img src="images/footer/email.png" alt="mail" />
          <img src="images/footer/linkedin.png" alt="linkedin" />
          <img src="images/footer/twitter (1).png" alt="twitter" />
          <img src="images/footer/instagram.png" alt="instagram" />
        </div>
      </div>
    </div>
    </Container>
  );
}

export default StudentProfile;
