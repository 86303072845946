// ChatComponent.js

import React, { useState } from 'react';
import './chat.css';
//import config from '../pages/config.json';
import axios from 'axios';
import config from '../pages/config.json';




const ChatComponent = () => {
  const [showBotContainer, setShowBotContainer] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [closeVisible, setCloseVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatVisible,setChatVisible] = useState(true)

  const showDiv = () => {
    setShowBotContainer(true);
    setCloseVisible(true);
    setChatVisible(false)
  };

  const hideDiv = () => {
    setShowBotContainer(false);
    setCloseVisible(false)
    setChatVisible(true)
  };

//   const sendMessage = async () => {
//     console.log(messageInput)
//     if (messageInput.trim() !== '') {
//       setMessages([...messages, { type: 'outgoing', text: messageInput }]);
//       setMessageInput('');

//       try {
//         const response = await axios.get(config.apiBaseUrl+`get-reply?message=${messageInput}`);

//         setMessages([...messages, { type: 'incoming', text: response.data }]);
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     }
//   };
const sendMessage = async () => {
    console.log(messageInput);
    if (messageInput.trim() !== '') {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'outgoing', text: messageInput },
      ]);
      setMessageInput('');
  
      try {
        const response = await axios.get(config.apiBaseUrl + `get-reply?message=${messageInput}`);
        setMessages((prevMessages) => [...prevMessages, { type: 'incoming', text: response.data }]);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  

  return (
    <div>
    {chatVisible &&(
      <button className="myBtn" id="bot_btn" onClick={showDiv}>
        <i className="fa fa-android" style={{ color: 'lightblue', fontSize: '28px' }}></i>
        <span className="icon"></span>
        chat
      </button>
      )}
      {closeVisible &&(
      <a className="myBtn" id="bot_btn" onClick={hideDiv}>
        <i className="fa fa-android" style={{ color: 'lightblue', fontSize: '28px' }}></i>
        <span className="icon"></span>
        Close
      </a>)}

      <div className="chat-container" style={{ display: showBotContainer ? 'block' : 'none' }}>

        <div className="messages" id="scrollingDiv">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.type}`} style={{ width: '100px' }}>
              <p>{msg.text}</p>
            </div>
          ))}
        </div>

        <div className="message-input">
          <input
            type="text"
            id="message"
            name="message"
            autoFocus
            placeholder="Type your message"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          <button className="create-button update" type="submit" name="chat_submit" onClick={sendMessage}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
