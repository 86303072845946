import React, { useState } from 'react';
// import './SinupPage.scss';
import styled from 'styled-components';
import './ForgotPage.css';
import config from './config.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// Create a styled component
const Container = styled.div`



`;


export const ForgotPage = () => {
    
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState(new FormData());
    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let error = '';

        if (!value) {
            error = `${name} is required`;
        } else {
            switch (name) {
                case 'email':
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        error = 'Invalid email address';
                    }
                    break;
                default:
                    break;
            }
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        formData.set(name, value);
        
            validateField(name, value);
        
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataObject = {};

        for (let [name, value] of formData.entries()) {
            formDataObject[name] = value;
        }

        const formDataJSON = JSON.stringify(formDataObject);
        const message = "verifyemail"
        //console.log(formData)

        if (Object.values(errors).every((error) => !error)) {

            for (let [name, value] of formData.entries()) {
                console.log(`${name}: ${value}`);
            }
            await axios.post(config.apiBaseUrl + 'verifyEmail', JSON.parse(JSON.stringify(formDataObject)))
                .then((response) => {
                    //alert(JSON.parse(JSON.stringify(response.data)).givencode);
                    localStorage.setItem('givencode', JSON.parse(JSON.stringify(response.data)).givencode)
                    localStorage.setItem('email', JSON.parse(JSON.stringify(response.data)).email)
                    navigate('/changepass')
                })
                .catch((error) => {
                    alert(JSON.stringify(formData));
                });
        }


    };

   const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

    return (
        <Container>
       <>
        <div className="topnav">
      <div className="logo-container">
        <img src="images/logo.png" className="logo" alt="Logo" />
        <span className="site-name">Edumetrics</span>
      </div>

                <div className="dropdown">
                    <a href="#" className="burger-icon" onClick={toggleMenu}>
                        &#9776;
                    </a>
                    {isOpen && (
                        <div className="dropdown-content">
                            
                            
                            <a href="#">
                                <img src="icons/icons8-home-50.png" alt="Test Icon" />
                                Home
                            </a>
                            <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                                <img src="icons/icons8-blog-50.png" />
                                Our Blog
                            </a>
                        </div>
                    )}
                </div>
            </div>

    <div className="container_forgot">
       <form className="forgot-password-form" method="POST"  >
        <h1>Forgot Password</h1>
        <p>Enter your email address  to reset your password.</p>
        
                      
                        <div className="form-group">

                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                onChange={handleInputChange}
                                
                            />
                            
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="form-group">
                            <button type="submit" onClick={handleSubmit}>Submit</button>
                        </div>
         
            
                        
      </form>
     </div>

     


    <div className="footer">
      <div></div>
      {/* <img src="images/uta_logo.png" alt="UTA" className="uta_logo" */}
      <div className="uni_info">
        <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>

        <p>701 S Nedderman Dr,</p>
        <p>Arlington, TX 76019</p>
        <p>817-090-xxxx</p>

        <p>Privacy Statement | Contact</p>
        <p>@2023 University of Texas at Arlington</p>
      </div>

      <div className="contact-us">
        <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
        <img src="images/footer/email.png" alt="mail" />
        <img src="images/footer/linkedin.png" alt="linkedin" />
        <img src="images/footer/twitter (1).png" alt="twitter" />
        <img src="images/footer/instagram.png" alt="instagram" />
      </div>
            </div>
        </>
        </Container>
    );

}
export default ForgotPage;