import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../pages/config.json';
import CreateExam from '../components/createExam';

const ExamList = () => {
   
    
  const [exams, setExams] = useState([]);
  const uniqueCourseIds = Array.from(new Set(exams.map(course => course.course_id)));
  // const uniqueCourses = [...new Set(exams.map((course) => course.course_id))];
  const [courses,setCourses] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [name, setName] = useState(selectedExam ? selectedExam.name : '');
  const sessionID=localStorage.getItem('sessionID');
//   alert('Hey',sessionID);

 /* useEffect(() => {
        fetchData();
    }, [sessionID]); */

    const fetchData = () => {
      console.log('Hwi',sessionID);
        fetch(`http://localhost/backend/exam.php?user_id=${sessionID}`,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer YourAccessToken', // If you have authentication
            },
        })
            .then((response) => response.json())
            .then((data) => setExams(data.data))
            //.then((data) => )
            .catch((error) => console.error('Error fetching courses:', error));
    };
    
   

  const handleEditExam = (exam) => {
    setSelectedExam(exam);
  };

  const handleEditCourse = (exam) => {
    setSelectedExam(exam);
  };

  

  const handleDeleteExam = (examId,courseId) => {
   
    console.log(courseId,examId);
    // alert(examId);
    fetch(`http://localhost/backend/examDelete.php?course_id=${courseId}&exam_id=${examId}`,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer YourAccessToken', // If you have authentication
        },
    })
        .then((response) => response.json())
        .then((data) => setExams(data.data))
        .then(()=>
            fetchData()
        )
        .catch((error) => console.error('Error fetching courses:', error)); 
  };

  const handleSubmit = () => {
    const updatedExam = {
      id: selectedExam ? selectedExam.id : null,
      name: name,
      selectedCourse:selectedCourse,
    };
    console.log('GetCourse',selectedCourse);
    console.log('Exam Name',name);
    // console.log('')
    
    // onSave(updatedExam);
    fetch(`http://localhost/backend/examAdd.php?user_id='jane.smith@example.com'&exam_name=${name}&course_name=${selectedCourse}`,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer YourAccessToken', // If you have authentication
        },
    })
        .then((response) => response.json())
        .then(()=>
            fetchData()
        )
        .catch((error) => console.error('Error fetching courses:', error)); 
    };

    const createCourseDropDown = async () => {
        const message = "courses";
        //alert(message)
        await axios
            .get(config.apiBaseUrl + `user?message=${message}`)
            .then((response) => {
                alert(JSON.parse(JSON.stringify(response.data.data)))
                setCourses(JSON.parse(JSON.stringify(response.data.data)));
                
            })
            .catch((error) => {
                alert(JSON.stringify('error'));
            });
    };

    const handleexamchange = (event) => {
        alert(event.target.value)
        //setSelectedExam(event.target.value)
        localStorage.setItem('examtype', event.target.value)
    }

    const handleCourseChange = (event) => {
        alert(event.target.value)
        //setSelectedExam(event.target.value)
        localStorage.setItem('course_id', event.target.value)
    }

    useEffect(() => {

        
        createCourseDropDown();
            
    }, []);

  return (

    <div className="container">
        <div className="edit-exam-form">
        <h2>{selectedExam ? 'Edit Exam' : 'Create New Exam'}</h2>
        
              
            <label htmlFor="courseSelect">Select Course:</label>
            <select
              id="courseSelect"
              onChange={handleCourseChange}
            > 
              <option value="">Select a course</option>
              {/* {exams.map((course) => ( */}
            {courses.map((course,index) => (
                <option key={index} value={course.course_id}>
                    {course.course_name}
                </option>
                
                
            ))}
              {/* // ))} */}
            </select>

              <label for="exam">Select an Exam Type:</label>
              <select id="exam_id" name="exam_id" onChange={handleexamchange}>
                  <option value="1" >quiz</option>
                  <option value="2" >midterms</option>
                  <option value="3" >assingment</option>
                  <option value="4" >exam</option>
              </select>


              <CreateExam />
        </div>
      
    </div>
  );
};

export default ExamList;
