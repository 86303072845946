import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../pages/StudentPage.css';

import styled from 'styled-components';
import config from '../pages/config.json';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';

const Container = styled.div`
`;

export const InstructorGrading = () => {
    
    const [grades, setGrades] = useState({});
    const [Courses, setCourses] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const [openAssessment, setOpenAssessment] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [exams, setExams] = useState([]);
    const [barChart, setBarChart] = useState();
    
   
    const sessionID = localStorage.getItem('sessionID');
   

    const handleGradeChange = (index, value) => {
        setGrades((prevGrades) => ({
            ...prevGrades,
            [index]: value,
        }));
    };

    const handleButtonClick = (index,gradeid) => {
        if (grades[index]<100){
        postGrade(gradeid, grades[index]);
        }
        else{
            alert("provided grade is in valid")
            getGrades(selectedCourse)
        }
    };

    const postGrade = async (gradeid,newgrade) => {
        try {
            const data1 = await axios.get(
                config.apiBaseUrl+`updategradebycourse?gradeid=${gradeid}&grade=${newgrade}`
            );
            const data = JSON.parse(JSON.stringify(data1.data));
           
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

   const handleCourseDropDown = (event) =>{
    let Course = event.target.value;
    setSelectedCourse(Course)
       getGrades(Course)
   }

    const getGrades = async (Course) => {
        
        
        
        try {
            const response = await axios.get(config.apiBaseUrl + `getGradeByCourse?courseid=${Course}`);
            setExams(JSON.parse(JSON.stringify(response.data.data)));
            
            setOpenAssessment(true)
           
            var gradeArr=Array()
            JSON.parse(JSON.stringify(response.data.data)).map(function(item) {
                
                gradeArr.push(item.grade)
              
         
            });
            setGrades(gradeArr)

        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const createDropDown = async () => {
        try {  
            const response = await axios.get(config.apiBaseUrl + `GetinstructorCourse?email=${sessionID}`);
            alert(JSON.parse(JSON.stringify(response.data.data)))
            setCourses(JSON.parse(JSON.stringify(response.data.data)));
            

        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };



    
    

    useEffect(() => {
        createDropDown()
        
    }, []);

    return (
        <Container>
            <div>
                <select id="user" onChange={handleCourseDropDown}>
                        <option>select course</option>

                        {Courses.map((course, index) => (
                            <option value={course.course_id}>{course.course_name}</option>
                        ))}

                    </select>

                    
      {openAssessment && 
      (
        <div className="assessments-table">
                            <table>
                            <tbody><tr>
              
              <th>Name</th>
              <th>courseName</th>
              <th>ExamName</th>
              <th>grade</th>
              <th>due_date</th>
              
            </tr>
            {exams.map((exam, index) => (
              <tr key={index}>
                <td>{exam.userName}</td>
                <td>{exam.courseName}</td>
                <td>{exam.assignment_name}</td>
                <td>
            <input
                className='input-mobile'
                type="number"
                name={`grade-${index}`}
                value={grades[index] || ''}
                onChange={(e) => handleGradeChange(index,e.target.value)}
            />

            </td>
            <td>
            <button onClick={() => {handleButtonClick(index,exam.grade_id)}}>POST Grade</button>
            </td>
            <td>{exam.due_date}</td>
            </tr>
            ))}

             
              
            </tbody>
                            </table>
                        </div>
        )}

                

                </div>



                
              
        </Container>
    );
}
export default InstructorGrading;