import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate }  from 'react-router-dom';
import styled from 'styled-components';
import config from '../pages/config.json';

const Container = styled.div`
  /* Add your styling here */
`;

function ProfileComponent() {
  const sessionID = localStorage.getItem('sessionID');
    const [userID, setUserID] = useState('');
    const usertype = localStorage.getItem('usertype');
    const [showForm, setShowForm] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)
    const navigate = useNavigate();
    

  const [users,setUsers] = useState([]);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

    const getUserNames = async () => {
        
    const message = usertype;
      alert(message)
    await axios
      .get(config.apiBaseUrl + `user?message=${message}`)
      .then((response) => {
        alert(JSON.parse(JSON.stringify(response.data.data)))
          setUsers(JSON.parse(JSON.stringify(response.data.data)));
          setShowDropDown(true)
      })
      .catch((error) => {
        alert(JSON.stringify(formData));
      });
  };

 const handleDropDown = async (e) =>{
    const email= e.target.value;
   // alert(userid)
    setUserID(email);
    await axios
      .get(config.apiBaseUrl + `profile?email=${email}`)
      .then((response) => {
          setFormData(JSON.parse(JSON.stringify(response.data.data))[0]);
          setShowForm(true)
      })
      .catch((error) => {
        alert(JSON.stringify(formData));
      });
 };

const editProfileData = async (e) => {
    e.preventDefault();
    //const message = 'editprofile';

    await axios
      .put(config.apiBaseUrl + `EditProfile?email=${userID}`, formData)
      .then((response) => {
          setFormData(JSON.parse(JSON.stringify(response.data.data))[0]);
          setShowDropDown(false)
          setShowForm(false)
          getUserNames()
      })
      .catch((error) => {
        alert(JSON.stringify(formData));
      });
    };

    const handleAddUser = () => {

        navigate('/adduser')
    }
    const handleAdminProfile = () => {

        navigate('/profile')
    }

    useEffect(() => {
        //alert(usertype)
      
          getUserNames();
      
  }, []);

  return (
    <Container>
          <div>
              {
                  showDropDown && (users.length > 0 ? (
        <select id="users" onChange={handleDropDown}>
                          {users.map((user, index) => (
                              <option value={user.email}>{user.username}</option>
                          ))}
                        
        </select>
              ) : (
                          <p>No users available.</p>
              )
              )}
              {showForm && (
                  //formData.length > 0 ? (
        <div style={{ marginTop: '100px', marginBottom: '100px' }}>
          <div className="prof_container">
            <form id="profile-form">
              <div className="form-group">
                <label htmlFor="firstName">First Name:</label>
                <input type="text" id="firstName" name="first_name" value={formData.first_name} required onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name:</label>
                <input type="text" id="lastName" name="last_name" value={formData.last_name} required onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} required onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="mobile_number" value={formData.mobile_number} required onChange={handleChange} />
              </div>
              <div className="form-group">
                <button type="button" id="edit-button" onClick={editProfileData}>
                  Edit Profile
                </button>
              </div>
            </form>
          </div>
                  </div>
                 // ) : (
                 //     <p></p>)
              )}
              
                  <button onClick={handleAddUser}> ADD new {usertype}</button>
              
      </div>
    </Container>
  );
}

export default ProfileComponent;
