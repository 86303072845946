import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './Pcoordinator.css'; 
import config from './config.json';// You can create an appropriate CSS file for styling

function AdminDashboard() {
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [curricula, setCurricula] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [newCurriculum, setNewCurriculum] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const sessionID = localStorage.getItem('sessionID');
  const location = useLocation();
  
  useEffect(() => {
    fetchPrograms();
    fetchData();
  }, []);
   // Fetch programs and initial messages on component mount

   const messageStyle = {
    border: '1px solid #ccc',
    padding: '10px',
    height: '300px',
    overflowY: 'auto',
};

const sentMessageStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '5px',
    margin: '5px',
    borderRadius: '5px',
    textAlign: 'right',
};

const receivedMessageStyle = {
    backgroundColor: '#f2f2f2',
    padding: '5px',
    margin: '5px',
    borderRadius: '5px',
    textAlign: 'left',
};

const inputStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginRight: '10px',
    flex: 1,
};

const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
};

  const fetchPrograms = async () => {
    try {
        const response = await axios.get(config.apiBaseUrl + 'pcc.php'); // Replace with your API endpoint
      setPrograms(response.data.data.data);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const fetchCourses = async (programId) => {
    try {
        const response = await axios.get(config.apiBaseUrl + `program.php?programId=${programId}`);
      setCourses(response.data.data.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchCurricula = async (courseId) => {
    try {
      setSelectedCourse(courseId);
        const response = await axios.get(config.apiBaseUrl + `course.php?courseId=${courseId}`);
      setCurricula(response.data.data.data);
    } catch (error) {
      console.error('Error fetching curricula:', error);
    }
  };

  const addCurriculum = async (event) => {
    const newCurriculum = event.target.value;
    if (selectedCourse && newCurriculum) {
      try {
          await axios.post(config.apiBaseUrl + 'curriculum.php', {
          courseId: selectedCourse,
          curriculumName: newCurriculum,
        });
        fetchCurricula(selectedCourse);
        setNewCurriculum('');
      } catch (error) {
        console.error('Error adding curriculum:', error);
      }
    }
  };

  const deleteCurriculum = async (curriculumId) => {
    try {
        await axios.delete(config.apiBaseUrl + `curriculum.php?curriculumId=${curriculumId}`);
      fetchCurricula(selectedCourse);
    } catch (error) {
      console.error('Error deleting curriculum:', error);
    }
  };

  const editCurriculum = async (curriculumId, newCurriculumName) => {
    try {
        await axios.put(config.apiBaseUrl + `curriculum.php?curriculumId=${curriculumId}`, {
        newName: newCurriculumName,
        course: selectedCourse,
      });
      fetchCurricula(selectedCourse);
    } catch (error) {
      console.error('Error editing curriculum:', error);
    }
  };

  const fetchData = async () => {
    // Fetch initial messages
    try {
      const response = await axios.post(config.apiBaseUrl + 'messageController.php', {
        newMessage: 'loading data',
        contact: location.state.contact,
        user: sessionID,
      });
      const conversationData = response.data.data.data;
      if (conversationData === undefined) {
        alert(response.data.data.data);
      } else {
        const updatedMessages = [...messages];
        for (let i = 0; i < conversationData.length; i++) {
          if (location.state.user === conversationData[i].sender_email) {
            updatedMessages.push({ text: conversationData[i].message_content, sender: 'You' });
          } else {
            updatedMessages.push({ text: conversationData[i].message_content, sender: conversationData[i].sender_email });
          }
        }
        setMessages(updatedMessages);
        setNewMessage('');
        alert(JSON.stringify(messages));
      }
    } catch (error) {
      alert('error');
    }
  };

  const sendMessage = async () => {
    if (newMessage.trim() !== '') {
      const response = await axios.post(config.apiBaseUrl + 'messageController.php', {
        newMessage: newMessage,
        contact: location.state.contact,
        user: location.state.user,
      });
      alert(JSON.stringify(response));
      const updatedMessages = [...messages, { text: newMessage, sender: 'You' }];
      setMessages(updatedMessages);
      setNewMessage('');
    }
  };

  const handleInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  return (
    <div>
      <div className="admin-dashboard">
        {/* Your top navigation and other common UI elements can be here */}
        {/* ... */}

        <div className="container">
          {/* Permission Management */}
          <div>
            {/* Render the list of programs */}
            <select onChange={(e) => fetchCourses(e.target.value)}>
              {programs.map((program) => (
                <option key={program.program_id} value={program.program_id}>
                  {program.program_name}
                </option>
              ))}
            </select>

            {/* Render the list of courses */}
            <select onChange={(e) => fetchCurricula(e.target.value)}>
              {courses.map((course) => (
                <option key={course.course_id} value={course.course_id}>
                  {course.course_name}
                </option>
              ))}
            </select>

            {/* Render the list of curricula */}
            <ul>
              {curricula.map((curriculum) => (
                <li key={curriculum.component_id}>
                  {curriculum.component_name}
                  <button onClick={() => deleteCurriculum(curriculum.component_id)}>Delete</button>
                  <button
                    onClick={() => {
                      const newName = prompt('Enter new curriculum name:');
                      if (newName) {
                        editCurriculum(curriculum.component_id, newName);
                      }
                    }}
                  >
                    Edit
                  </button>
                </li>
              ))}
            </ul>

            {/* Add new curriculum */}
            <div>
              <input
                type="text"
                placeholder="New Curriculum Name"
                value={newCurriculum}
                onChange={(e) => setNewCurriculum(e.target.value)}
              />
              <button onClick={addCurriculum}>Add Curriculum</button>
            </div>
          </div>

          {/* Account Management */}
          <div>
            <h1>Messaging System</h1>
            <div style={messageStyle} className="message-container">
              {messages.map((message, index) => (
                <div
                  key={index}
                  style={message.sender === 'You' ? sentMessageStyle : receivedMessageStyle}
                  className="message"
                >
                  {message.sender}: {message.text}
                </div>
              ))}
            </div>
            <div className="message-input">
              <input
                type="text"
                placeholder="Type a message..."
                style={inputStyle}
                value={newMessage}
                onChange={handleInputChange}
              />
              <button style={buttonStyle} onClick={sendMessage}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Your footer and other common UI elements can be here */}
      {/* ... */}
    </div>
  );
}

export default AdminDashboard;
