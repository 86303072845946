import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import config from '../pages/config.json';

const Container = styled.div`
  /* Add your styling here */
`;

function Coursemanagement() {
    const sessionID = localStorage.getItem('sessionID');
    const [userID, setUserID] = useState('');
    const usertype = localStorage.getItem('usertype');
    const [showForm, setShowForm] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)
    const navigate = useNavigate();
    const role = localStorage.getItem('role')

    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        course_name: '',
        start_date: '',
        last_date:'',
        semester: '',
        course_objective: '',
    });

    


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const getCourseNamesForinstructor = async () => {
        //const message = usertype;
        //alert(message)
        await axios
            .get(config.apiBaseUrl + `getcourseforemail?email=${sessionID}`)
            .then((response) => {
                alert(JSON.parse(JSON.stringify(response.data.data)))
                setUsers(JSON.parse(JSON.stringify(response.data.data)));
                setShowDropDown(true)
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
    };
    

    const getUserNames = async () => {
        const message = usertype;
        alert(message)
        await axios
            .get(config.apiBaseUrl + `user?message=${message}`)
            .then((response) => {
                alert(JSON.parse(JSON.stringify(response.data.data)))
                setUsers(JSON.parse(JSON.stringify(response.data.data)));
                setShowDropDown(true)
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
    };

    const handleDropDown = async (e) => {
        const courseid = e.target.value;
        // alert(userid)
        setUserID(courseid);
        await axios
            .get(config.apiBaseUrl + `getcoursedata?courseid=${courseid}`)
            .then((response) => {
               // alert(JSON.parse(JSON.stringify(response.data.data)));
                setFormData(JSON.parse(JSON.stringify(response.data.data))[0]);
                setShowForm(true)
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
    };

    const editProfileData = async (e) => {
        e.preventDefault();
        //const message = 'editprofile';

        await axios
            .put(config.apiBaseUrl + `updatecoursedata?courseid=${userID}`, formData)
            .then((response) => {
                ;
                setShowDropDown(false)
                setShowForm(false)
                if (role==="admin"){getUserNames()}
                else {getCourseNamesForinstructor()}
                
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
    };

    const handleAddUser = () => {

        navigate('/addcourse')
    }

    const handleDeleteUser = async () => {
        await axios
            .put(config.apiBaseUrl + `deleteuser?courseid=${userID}`, formData)
            .then((response) => {
                setShowDropDown(false)
                setShowForm(false)
                if (role==="admin"){getUserNames()}
                else {getCourseNamesForinstructor()}
                
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
        
    }
    

    useEffect(() => {
        //alert(usertype)
        alert(role)
        if (role==="admin"){
        getUserNames();
        }else if (role==="instructor"){
            getCourseNamesForinstructor();
        }

    }, []);

    return (
        <Container>
            <div>
                {showDropDown && (
                    
                        users.length > 0 ? (
                            <select id="users" onChange={handleDropDown}>
                                <option>select course</option>
                                {users.map((user, index) => (
                                    <option value={user.course_id}>{user.course_name}</option>
                                ))}

                            </select>
                        ) : (
                            <p></p>
                        )
                    
                )}
                {showForm && (
                    //formData.length > 0 ? (
                    
                    <div style={{ marginTop: '100px', marginBottom: '100px' }}>
                        <div className="prof_container">
                            <form id="profile-form">
                                <div className="form-group">
                                    <label htmlFor="course_name">course Name:</label>
                                    <input type="text" id="course_name" name="course_name" value={formData.course_name} required onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="start_date">startdate:</label>
                                    <input
                                        type="date"
                                        id="start_date"
                                        name="start_date"
                                        pattern="\d{4}-\d{2}-\d{2}"
                                        placeholder="YYYY-MM-DD"
                                        value={formData.start_date}
                                        required
                                     onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="enddate">enddate:</label>
                                    <input
                                        type="date"
                                        id="last_date"
                                        name="last_date"
                                        pattern="\d{4}-\d{2}-\d{2}"
                                        placeholder="YYYY-MM-DD"
                                        value={formData.last_date}
                                        required
                                        onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="semester">semester:</label>
                                    <input type="text" id="semester" name="semester" value={formData.semester} required onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="course_objective">objective:</label>
                                    <input type="text" id="course_objective" name="course_objective" value={formData.course_objective} required onChange={handleChange} />
                                </div>
                                
                                <div className="form-group">
                                    <button type="button" id="edit-button" onClick={editProfileData}>
                                        Edit Profile
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                   // ) : (
                   // <p></p>)
                )}
                
                    <button onClick={handleAddUser}> ADD new {usertype}</button>
                    <button onClick={handleDeleteUser}> DELETE new {usertype}</button>
               
            </div>
        </Container>
    );
}

export default Coursemanagement;
