import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './LoginPage.css';
import config from './config.json';
import axios from 'axios';
import styled from 'styled-components';
// Create a styled component
const Container = styled.div``;

export const LoginPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    
    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        //alert(config.apiBaseUrl);
        alert(formData)
        axios.post(config.apiBaseUrl+'login', formData)
            .then((response) =>
            {   localStorage.setItem('sessionID',response.data.data.email);
                localStorage.setItem('role',response.data.data.rolename);
                const role = response.data.data.rolename;
                alert(role)
                if (role === 'student') {
                    navigate('/student');
                } else if (role === 'instructor') {
                    navigate('/instructor');
                } else if (role === 'pc') {
                    navigate('/pc');
                } else if (role === 'qaofficer') {
                    navigate('/qa');
                } else if (role === 'admin') {
                    navigate('/admin');
                } else if (role === '') {
                    navigate('/login');
                } 

                alert(JSON.stringify(response));
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
    }
    

    useEffect(() => {
        // Fetch programs and courses on component mount
        if (localStorage.getItem('role')){
            const role=localStorage.getItem('role')

            
            alert(role)
            if (role === 'student') {
                navigate('/student');
            } else if (role === 'instructor') {
                navigate('/instructor');
            } else if (role === 'pc') {
                navigate('/pc');
            } else if (role === 'qaofficer') {
                navigate('/qa');
            } else if (role === 'admin') {
                navigate('/admin');
            } else if (role === '') {
                navigate('/login');
            } 
        }
        
    }, []);

    

    return (
        <Container>
        <div>
            <div className="topnav">
                <div className="logo-container">
                    <img src="images/logo.png" className="logo" alt="Logo" />
                    <span className="site-name">Edumetrics</span>
                </div>
                <div className="dropdown">
                    <a href="#" className="burger-icon" onClick={toggleMenu}>
                        &#9776;
                    </a>
                    {isOpen && (
                        <div className="dropdown-content">
                            
                            <a href="/home">
                                <img src="icons/icons8-home-50.png" alt="Test Icon" />
                                Home
                            </a>
                            <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                                <img src="icons/blog.png" />
                                Our Blog
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="container_login" style={{ backgroundColor: '#f5f5f5' }}>
                <h1>Login</h1>
                    <form className="login-form" method="POST" onChange={handleChange}>
                    <label for="email">Email:</label>
                    <input type="text" name="email" required/><br />
                    <label for="password">Password:</label>
                    <input type="password" name="password" required/><br />
                    
                    <input type="submit" value="login" name="submit" onClick={handleSubmit} />
                </form>
              
                <p>
                    <a href="/sinup">Create a new account</a> |{' '}
                    <a href="/forgot">Forgot Password</a>
                </p>
            </div>
            <div className="footer">
                <div></div>
                <div className="uni_info">
                    <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                    <p>701 S Nedderman Dr,</p>
                    <p>Arlington, TX 76019</p>
                    <p>817-090-xxxx</p>
                    <p>Privacy Statement | Contact</p>
                    <p>@2023 University of Texas at Arlington</p>
                </div>
                <div className="contact-us">
                    <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                    <img src="images/footer/email.png" alt="mail" />
                    <img src="images/footer/linkedin.png" alt="linkedin" />
                    <img src="images/footer/twitter (1).png" alt="twitter" />
                    <img src="images/footer/instagram.png" alt="instagram" />
                </div>
            </div>
        </div>
        </Container>
    
    );

}
export default LoginPage;