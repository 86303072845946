import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../pages/SinupPage.css';
import config from '../pages/config.json';
import axios from 'axios';

export const CreateExam = () => {


    const [errors, setErrors] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const course_id = localStorage.getItem('course_id')
    const exam_id = localStorage.getItem('examtype')
    const [formData, setFormData] = useState({
        assignment_name: '',
        due_date: '',
    });




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios
            .put(config.apiBaseUrl + `AddExam?course_id=${course_id}&exam_id=${exam_id}`, formData)
            .then((response) => {
                alert(JSON.parse(JSON.stringify(response.data.data)))


            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });



    };


    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

    return (


        <div>
           

            <div className="container_sinup">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="assignment_name">assignment_name:</label>
                        <input
                            type="text"
                            id="assignment_name"
                            name="assignment_name"
                            onChange={handleChange}
                        />

                    </div>
                    

                    <div className="form-group">
                        <label htmlFor="due_date">due_date:</label>
                        <input
                            type="date"
                            id="due_date"
                            name="due_date"
                            pattern="\d{4}-\d{2}-\d{2}"
                            placeholder="YYYY-MM-DD"
                            value={formData.due_date}
                            required
                            onChange={handleChange} />
                    </div>

                    
                    <div className="form-group">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>

           
        </div>



    );







}

export default CreateExam;