import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../pages/StudentPage.css';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import config from '../pages/config.json';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
const Container = styled.div`
`;

export const ReportPage = () => {
    const [scheduleData, setScheduleData] = useState([]);
    //const [Grades, setGrades] = useState([]);
    const [Courses, setCourses] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const [openAssessment, setOpenAssessment] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    const role = localStorage.getItem('role')
    const [exams, setExams] = useState([]);
    const [barChart, setBarChart] = useState();
    const flagRef = useRef("");
    let count = useRef("");
    const navigate = useNavigate();
    const sessionID = localStorage.getItem('sessionID');

    //let barChart, pieChart;

    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };



   

    const handleCourseDropDown = async (event) => {
        //const message="courseDetails"
        let Course = event.target.value;

        try {
            const response = await axios.get(config.apiBaseUrl + `getGradeByCourse?courseid=${Course}`);
            setExams(JSON.parse(JSON.stringify(response.data.data)));
            //alert(JSON.stringify(response.data.data));
            setOpenAssessment(true)
            handleAnalytics(response.data.data)
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const createDropDown = async () => {
        try {  
            const response = await axios.get(config.apiBaseUrl + `GetinstructorCourse?email=${sessionID}`);
            alert(JSON.parse(JSON.stringify(response.data.data)))
            setCourses(JSON.parse(JSON.stringify(response.data.data)));
            

        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const createDropDownCourse = async () => {
        try {  
            const message="courses"
            const response = await axios.get(config.apiBaseUrl + `user?message=${message}`);
            alert(JSON.parse(JSON.stringify(response.data.data)))
            setCourses(JSON.parse(JSON.stringify(response.data.data)));
            

        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };



    
    const handleAnalytics = async (gradeData) => {

        try {
            
            
            const data = JSON.parse(JSON.stringify(gradeData))
           
            alert("from handle analytics")
            const keys = Object.keys(data);
            
            if (barChart) {
                alert("hello")
                barChart.destroy();
            }

            const labels = [];
            const dataValues = [];

            data.map(function(item) {
                ////alert(item.grade)
                labels.push(item.userName+"-"+item.assignment_name+"-"+item.courseName);
                dataValues.push(item.grade);
                //setCourseName(item.courseName)
         
            });

           
            var barChartData = {
                labels: labels,
                datasets: [{
                  label: 'Student Performance',
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                  data: dataValues
                }]
              };
              //alert(barChartData.labels[0])
              //alert(barChartData.data[0])
              
              
              // Create and render the Bar Chart
              var barChartCanvas = document.getElementById('barChart');
              setBarChart(new Chart(barChartCanvas, {
                type: 'bar',
                data: barChartData,
                options: {
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  }
                }
              }));

            
            setContentVisible(true);
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    useEffect(() => {
        if (role==="instructor"){
        createDropDown()}
        else if (role==="pc"){
            createDropDownCourse()
        }
        
    }, []);

    return (
        <Container>
            <div>
                <select id="user" onChange={handleCourseDropDown}>
                        <option>select course</option>

                        {Courses.map((course, index) => (
                            <option value={course.course_id}>{course.course_name}</option>
                        ))}

                    </select>

                    
      {openAssessment && 
      (
        <div className="assessments-table">
                            <table>
                            <tbody><tr>
              
              <th>Name</th>
              <th>courseName</th>
              <th>grade</th>
              <th>due_date</th>
              
            </tr>
            {exams.map((exam, index) => (
              <tr>
                <td>{exam.userName}</td>
                <td>{exam.courseName}</td>
                <td>{exam.grade}</td>
                <td>{exam.due_date}</td>
              </tr>
            ))}
              
            </tbody>
                            </table>
                        </div>
        )}

                <div style={{ display: isContentVisible ? 'block' : 'none' }} className="chart-container-coordinator">
                        {/* Bar Chart Container */}
                        <div className="chart">
                            <canvas id="barChart" />
                        </div>
                        {/* Pie Chart Container */}

                    </div>
                </div>



                
              
        </Container>
    );
}
export default ReportPage;