import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssignmentList = () => {
   
  
  const [assigns, setAssigns] = useState([]);
  const [selectedAssign, setSelectedAssign] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [name, setName] = useState(selectedAssign ? selectedAssign.name : '');
  const sessionID=localStorage.getItem('sessionID');
  const uniqueCourseIds = Array.from(new Set(assigns.map(course => course.course_id)));
 
//   alert('Hey',sessionID);

  useEffect(() => {
        fetchData();
    }, [sessionID]); 

    const fetchData = () => {
        fetch(`http://localhost/backend/assign.php?user_id=${sessionID}`,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer YourAccessToken', // If you have authentication
            },
        })
            .then((response) => response.json())
            .then((data) => setAssigns(data.data))
            .catch((error) => console.error('Error fetching courses:', error));
    };
    
   

  const handleEditAssign = (assign) => {
    setSelectedAssign(assign);
  };

  

  const handleDeleteAssign = (assignId,courseId) => {
   

    // alert(assignId);
    fetch(`http://localhost/backend/assignDelete.php?assign_id=${assignId}&course_id=${courseId}`,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer YourAccessToken', // If you have authentication
        },
    })
        .then((response) => response.json())
        .then((data) => setAssigns(data.data))
        .then(()=>
            fetchData()
        )
        .catch((error) => console.error('Error fetching courses:', error)); 
  };

  const handleSubmit = () => {
    const updatedassign = {
      id: selectedAssign ? selectedAssign.id : null,
      name: name,
      selectedCourse:selectedCourse,
    };
    
    // onSave(updatedassign);
    fetch(`http://localhost/backend/assignAdd.php?user_id='jane.smith@example.com'&assign_name=${name}&course_name=${selectedCourse}`,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer YourAccessToken', // If you have authentication
        },
    })
        .then((response) => response.json())
        .then(()=>
            fetchData()
        )
        .catch((error) => console.error('Error fetching courses:', error)); 
  };

  return (

    <div className="container">
        <div className="edit-assign-form">
        <h2>{selectedAssign ? 'Edit Assignment' : 'Create New Assignment'}</h2>
        <div className="form-group">
            <label htmlFor="assignName">Assignment Name:</label>
            <input
            type="text"
            id="assignName"
            value={name}
            onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="courseSelect">Select Course:</label>
            <select
              id="courseSelect"
              onChange={(e)=>setSelectedCourse(e.target.value)}
            > 
              <option value="">Select a course</option>
              {/* {exams.map((course) => ( */}
              {uniqueCourseIds.map((courseId) => {
                const course = assigns.find((c) => c.course_id === courseId);
                return (
                <option key={course.course_id} value={course.course_id}>
                  {course.course_name}
                </option>
                );
            })}
              {/* // ))} */}
            </select>
        </div>
        <div className="form-group">
            <button onClick={handleSubmit}>
            {selectedAssign ? 'Save Changes' : 'Create Assignment'}
            </button>
        </div>
        </div>
      <div className="ua" id="assignSection">
        <div className="upload">
          {assigns.map((assign) => (
            <div className="exams" >
              <h6>{assign.assignment_name}</h6>
              <h6>{assign.course_name}</h6>
              <img
                src="icons/editing.png"
                alt="edit"
                onClick={() => handleEditAssign(assign)}
              />
              <img
                src="icons/delete.png"
                alt="delete"
                onClick={() => handleDeleteAssign(assign.assignment_name,assign.course_id)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssignmentList;
