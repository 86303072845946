import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SinupPage.css';
import config from './config.json';
import axios from 'axios';

export const Adduser = () => {

    
    const [errors, setErrors] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

   
    

const [formData, setFormData] = useState({
    course_name: '',
    start_date: '',
    last_date: '',
    semester: '',
    course_objective: '',
});




const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios
            .post(config.apiBaseUrl + 'addCourse', formData)
            .then((response) => {
                alert(JSON.parse(JSON.stringify(response.data.data)))

                
            })
            .catch((error) => {
                alert(JSON.stringify(formData));
            });
        

       
    };


    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

    return (


        <div>
            <div className="topnav">
                <div className="logo-container">
                    <img src="images/logo.png" className="logo" alt="Logo" />
                    <span className="site-name">Edumetrics</span>
                </div>

             </div>

            <div className="container_sinup">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="course_name">coursename:</label>
                        <input
                            type="text"
                            id="course_name"
                            name="course_name"
                            onChange={handleChange}
                           />
                        
                    </div>
                    <div className="form-group">
                        <label htmlFor="semester">semester:</label>
                        <input
                            type="text"
                            id="semester"
                            name="semester"
                            onChange={handleChange}
                        />

                    </div>

                    <div className="form-group">
                        <label htmlFor="start_date">startdate:</label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            pattern="\d{4}-\d{2}-\d{2}"
                            placeholder="YYYY-MM-DD"
                            value={formData.start_date}
                            required
                            onChange={handleChange} />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="enddate">enddate:</label>
                        <input
                            type="date"
                            id="last_date"
                            name="last_date"
                            pattern="\d{4}-\d{2}-\d{2}"
                            placeholder="YYYY-MM-DD"
                            value={formData.last_date}
                            required
                            onChange={handleChange} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="course_objective">courseobjective:</label>
                        <input
                            type="text"
                            id="course_objective"
                            name="course_objective"
                            onChange={handleChange}
                            required
                        />
                        
                    </div>
                    <div className="form-group">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>

            <div className="footer">
                <div></div>
                <div className="uni_info">
                    <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                    <p>701 S Nedderman Dr,</p>
                    <p>Arlington, TX 76019</p>
                    <p>817-090-xxxx</p>
                    <p>Privacy Statement | Contact</p>
                    <p>@2023 University of Texas at Arlington</p>
                </div>

                <div className="contact-us">
                    <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                    <img src="images/footer/email.png" alt="mail" />
                    <img src="images/footer/linkedin.png" alt="linkedin" />
                    <img src="images/footer/twitter (1).png" alt="twitter" />
                    <img src="images/footer/instagram.png" alt="instagram" />
                </div>
            </div>
        </div>



    );







}

export default Adduser;