import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SinupPage.css';
import config from './config.json';
import axios from 'axios';

export const Adduser = () => {

    const [formData, setFormData] = useState(new FormData());
    const [errors, setErrors] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const validateField = (name, value) => {
        let error = '';

        if (!value) {
            error = `${name} is required`;
        } else {
            switch (name) {
                case 'email':
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        error = 'Invalid email address';
                    }
                    break;
                case 'password':
                    if (value.length < 8) {
                        error = 'Password should be at least 8 characters';
                    }
                    break;
                case 'mobileNumber':
                    if (!/^\d{10}$/.test(value)) {
                        error = 'Invalid mobile number';
                    }
                    break;
                case 'rolename':
                    if (!(value === "student" || value === "instructor" || value === "programcoordinator" || value === "admin" || value === "qaofficer")) {
                        error = 'Invalid Rolename';
                    }
                    break;
                default:
                    break;
            }
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        formData.set(name, value);
        if (name !== 'firstname' && name !== 'lastname') {
            validateField(name, value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataObject = {};

        for (let [name, value] of formData.entries()) {
            formDataObject[name] = value;
        }
        formDataObject['rolename'] = localStorage.getItem('usertype')

        const formDataJSON = JSON.stringify(formDataObject);

        console.log(formData)

        if (Object.values(errors).every((error) => !error)) {

            for (let [name, value] of formData.entries()) {
                console.log(`${name}: ${value}`);
            }
            await axios.post(config.apiBaseUrl + 'sinup', formDataObject)
                .then((response) => {
                    alert(JSON.stringify(response));
                    //navigate('/');
                })
                .catch((error) => {
                    alert(JSON.stringify(formData));
                });
        }
    };


    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };

    return (


        <div>
            <div className="topnav">
                <div className="logo-container">
                    <img src="images/logo.png" className="logo" alt="Logo" />
                    <span className="site-name">Edumetrics</span>
                </div>

                
            </div>

            <div className="container_sinup">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstname">Firstname:</label>
                        <input
                            type="text"
                            id="firstname"
                            name="firstname"

                            onChange={handleInputChange}

                        />
                        {/* No need to display errors for firstname */}
                    </div>

                    <div className="form-group">
                        <label htmlFor="lastname">Lastname:</label>
                        <input
                            type="text"
                            id="lastname"
                            name="lastname"

                            onChange={handleInputChange}

                        />
                        {/* No need to display errors for lastname */}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={handleInputChange}
                        />
                        {errors.email && <span className="error">{errors.email}</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={handleInputChange}
                        />
                        {errors.password && (
                            <span className="error">{errors.password}</span>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="mobileNumber">Mobile Number:</label>
                        <input
                            type="tel"
                            id="mobileNumber"
                            name="mobileNumber"
                            onChange={handleInputChange}
                        />
                        {errors.mobileNumber && (
                            <span className="error">{errors.mobileNumber}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="rolename">Role Name:</label>
                        <input type="text"
                            name="rolename"
                            id="rolename"
                            value={localStorage.getItem('usertype')}
                            readOnly
                        />
                            
                    </div>

                    <div className="form-group">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>

            <div className="footer">
                <div></div>
                <div className="uni_info">
                    <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                    <p>701 S Nedderman Dr,</p>
                    <p>Arlington, TX 76019</p>
                    <p>817-090-xxxx</p>
                    <p>Privacy Statement | Contact</p>
                    <p>@2023 University of Texas at Arlington</p>
                </div>

                <div className="contact-us">
                    <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                    <img src="images/footer/email.png" alt="mail" />
                    <img src="images/footer/linkedin.png" alt="linkedin" />
                    <img src="images/footer/twitter (1).png" alt="twitter" />
                    <img src="images/footer/instagram.png" alt="instagram" />
                </div>
            </div>
        </div>



    );







}

export default Adduser;