import React, { useState,useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './StudentPage.css';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import config from './config.json';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
const Container = styled.div`
`;

export const StudentPage = () => {
    const [scheduleData, setScheduleData] = useState([]);
    const [courses, setCourses] = useState([]);
    const [sems, setSems] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const [openAssessment, setOpenAssessment] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    const [barChart,setBarChart] = useState();
    const flagRef = useRef("");
    let count=useRef("");
    const navigate = useNavigate();
    const sessionID=localStorage.getItem('sessionID');

    //let barChart, pieChart;

      
    

        const handleExpandButtonClick = async () => {
            //const message="schedule"
            if (flagRef.current === "") {
                setScheduleData(["Day 1 Schedule"]);
                flagRef.current = "hide";
            } else if (flagRef.current === "hide") {
                try {
                    const response = await axios.get(config.apiBaseUrl+`schedule?email=${sessionID}`); 
                    setScheduleData(JSON.parse(JSON.stringify(response.data.data)));
                    alert(JSON.stringify(response.data.data));
                } catch (error) {
                    console.error('Error fetching programs:', error);
                }
                
                
                flagRef.current = "open";
            } else if (flagRef.current === "open") {
                setScheduleData(["Day 1 Schedule"]);
                flagRef.current = "hide";
            }
        };

    const handleCourseDropDown = async (event) => {
            //const message="courseDetails"
            let sem = event.target.value; 
           
            try {
                const response = await axios.get(config.apiBaseUrl +`course-details?sem=${sem}&email=${sessionID}`); 
                setCourses(JSON.parse(JSON.stringify(response.data.data)));
                alert(JSON.stringify(response.data.data));
            } catch (error) {
                console.error('Error fetching programs:', error);
            }
    };

    const createDropDown = async () => {
     try {  //const message="semDetails"
         const response = await axios.get(config.apiBaseUrl +`sem-details?email=${sessionID}`);
            setSems(JSON.parse(JSON.stringify(response.data.data)));
            //alert(JSON.parse(JSON.stringify(response.data.data)).semester);
           
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    

    const handleContact = async (course) => {
        
        try { 
               
            const response = await axios.get(config.apiBaseUrl + `contact-info?course=${course}&email=${sessionID}`);
            alert(JSON.parse(JSON.stringify(response.data.data[0])).email);

            localStorage.setItem('contact', JSON.parse(JSON.stringify(response.data.data[0])).email)
            navigate('/message')
           } catch (error) {
               console.error('Error fetching programs:', error);
           }
       };

       function getRandomData() {
        return Math.floor(Math.random() * 100);
      }

const handleAnalytics = async (course) => {
        
        try {  
            //const message = "analytics";
            const response = await axios.get(config.apiBaseUrl + `analytics?course=${course}&email=${sessionID}`);
        const data = JSON.parse(JSON.stringify(response.data.data));
        

        const keys = Object.keys(data[0]);
         alert(barChart)
        if (barChart) {
            alert("hello")
            barChart.destroy();
        }



        

// Initialize arrays to store labels and data
const labels = [];
const dataValues = [];



data.forEach((item) => {
    // Access properties of each dictionary
    labels.push(item.assignment_name)
    dataValues.push(item.grade);
    // Replace "propertyName" with the actual property you want to access
  });



console.log(labels)
console.log(dataValues)
// Bar Chart Data
var barChartData = {
  labels: labels,
  datasets: [{
    label: 'Student Performance',
    backgroundColor: 'rgba(75, 192, 192, 0.5)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 1,
    data: dataValues
  }]
};
//alert(barChartData.labels[0])
//alert(barChartData.data[0])


// Create and render the Bar Chart
var barChartCanvas = document.getElementById('barChart');
setBarChart(new Chart(barChartCanvas, {
  type: 'bar',
  data: barChartData,
  options: {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }
}));


// keys.forEach((key) => {
//     if (key==="assignment_name"){
//   labels.push(data[0][key]); }
//   if (key==="grade"){
//   dataValues.push(data[0][key]);}
// });




  
       
  
        // Set state to make the charts visible
        setContentVisible(true);
    } catch (error) {
        console.error('Error fetching programs:', error);
      }
       };


    const toggleMenu = () => {
        setIsOpen(!isOpen);


    };


    

    
   

  const Assessments = async (course) => {
    
setOpenAssessment(false);
    try {  
    let assessments=0;
        const response = await axios.get(config.apiBaseUrl +`assessments?course=${course}&email=${sessionID}`);
    
    assessments=JSON.parse(JSON.stringify(response.data.data))
    
     setAssessments(assessments);
     if (assessments.length>0){
     setOpenAssessment(true);
     }
   
   } catch (error) {
    console.error('Error fetching programs:', error);
   }
}

   

    useEffect(() => {
       
        if (localStorage.getItem('role')){
            const role=localStorage.getItem('role')

            
            alert(role)
            if (role === 'student') {
                navigate('/student');
                createDropDown();
            } else if (role === 'instructor') {
                navigate('/instructor');
            } else if (role === 'pc') {
                navigate('/pc');
            } else if (role === 'qaofficer') {
                navigate('/qa');
            } else if (role === 'admin') {
                navigate('/admin');
            } else if (role === '') {
                navigate('/login');
            } 
        }
    }, []);

      return (
        <Container>
            <div>
                <div className="topnav">
                    <div className="logo-container">
                        <img src="images/logo.png" className="logo" alt="Logo" />
                        <span className="site-name">Edumetrics</span>
                    </div>

                    <div className="dropdown">
                        <a href="#" className="burger-icon" onClick={toggleMenu}>
                            &#9776;
                        </a>
                        {isOpen && (
                            <div className="dropdown-content">
                                <a href="/profile">
                                    <img src="icons/user.png" alt="User Icon" />
                                    Student Profile
                                </a>
                                <a href="/student">
                                    <img src="icons/user.png" alt="User Icon" />
                                    Courses
                                </a>
                                <a href="#">
                                    <img src="icons/test.png" alt="Test Icon" />
                                    Grade Info
                              </a>
                               <a href="/communication">
                              Communication Tools
                               </a>
                                <a href="/home">
                                  <img src="icons/icons8-home-50.png" alt="Scholarship Icon" />
                                    Home
                                </a>
                                <a href="https://bxd3672.uta.cloud/wordpress_blog/">
                                  <img src="icons/blog.png" />
                                  Our Blog
                                </a>
                                <a href="/logout">
                                    <img src="icons/exit.png" alt="Exit Icon" />
                                    Log Out
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                <div className="container">
                    <div className="upcoming">
                      <h2>Upcoming Exams and Assignments</h2>
                      <button id="expandScheduleButton" onClick={handleExpandButtonClick}>Show Full Schedule</button>
                      
                        {Array.isArray(scheduleData) ? (
                            scheduleData.map((scheduleItem, index) => (
                                <div key={index} className="upcoming-item">
                                    <div className="left">
                                    <h3>{scheduleItem.type}</h3>
                                    <h3>{scheduleItem.assignment_name}</h3>
                                    
                                </div>
                                <div className="right">
                                    <p>{scheduleItem.due_date}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="upcoming-item">
                                <div className="left">
                                    <h3>Exam 1</h3>
                                    <h3>Assignment 2</h3>
                                    {/* Add more exam and assignment titles here */}
                                </div>
                                <div className="right">
                                    <p>Date: October 10, 2023</p>
                                    <p>Time: 9:00 AM</p>
                                    <p>Due Date: October 15, 2023</p>
                                </div>
                            </div>
                        )}
                    </div>



                    <select id="sem" onChange={handleCourseDropDown}>
                          {sems.map((sem, index) => (
                              <option value={sem.semester}>{sem.semester}</option>
                          ))}
                        
                    </select>

                    <div className="courses">
                        {courses.map((course, index) => (
                            <div key={index}>
                                <h6>{course.course_name} date : {course.start_date} to {course.last_date}</h6>
                                <div className="buttons">
                                    <button onClick={() => {Assessments(course.course_id)}}>Assessment</button>
                                    <button onClick={() => {handleAnalytics(course.course_id)}}>Analytics</button>
                                    <button onClick={handleContact}>Report</button>
                                </div>
                                <div className="buttons">
                                    <button onClick={() => {handleContact(course.course_id)}}>Contact TA</button>
                                    
                                </div>
                            </div>
                        ))}
                     </div>
{openAssessment && (
    <div className="assessments-table">
      <table>
        <thead>
          <tr>
            <th>Assignment Name</th>
            <th>Grade</th>
            <th>Status</th>
            <th>Due Date</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {assessments.map((assessment) => (
            <tr key={assessment.assignment_id}>
              
              <td>{assessment.assignment_name}</td>
    
              <td>{assessment.grade}</td>
              <td>{assessment.status}</td>
              <td>{assessment.due_date}</td>
              <td>{assessment.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                            )}
                           
        <div  style={{ display: isContentVisible ? 'block' : 'none' }} className="chart-container-coordinator">
            {/* Bar Chart Container */}
            <div className="chart">
              <canvas id="barChart" />
            </div>
            {/* Pie Chart Container */}
            
          </div>
         </div>

                

                <div className="footer">
                    <div></div>
                    <div className="uni_info">
                        <h4>THE UNIVERSITY OF TEXAS AT ARLINGTON</h4>
                        <p>701 S Nedderman Dr,</p>
                        <p>Arlington, TX 76019</p>
                        <p>817-090-xxxx</p>
                        <p>Privacy Statement | Contact</p>
                        <p>@2023 University of Texas at Arlington</p>
                    </div>
                    <div className="contact-us">
                        <img src="images/footer/facebook-app-symbol.png" alt="facebook" />
                        <img src="images/footer/email.png" alt="mail" />
                        <img src="images/footer/linkedin.png" alt="linkedin" />
                        <img src="images/footer/twitter (1).png" alt="twitter" />
                        <img src="images/footer/instagram.png" alt="instagram" />
                    </div>
                </div>
            </div>
            </Container>
        );
}
export default StudentPage;