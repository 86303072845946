import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNav from './pages/TopNav';
import Dropdown from './pages/Dropdown';
import StudentProfile from './pages/StudentProfile';
import Changepassword from './pages/changePassword';
import QualityAssuranceOfficerDashboard from './pages/QA';
import ContactUs from './pages/ContactUs';
import ServicesComponent from './pages/Services';
import AboutUs from './pages/AboutUs';
import InstructorDashboard from './pages/Instructor';
import InstructorProfile from './pages/InstructorProfile';
import ProgramCordinator from './pages/ProgramCordinator';
import Communication  from './pages/Communication';
import Message from './pages/Message';
import PointOfContact from './pages/PointOfContact';
import Charts from './pages/Charts';
import AdminDashboard from './pages/Admin';
import StudentPage  from './pages/StudentPage';
import HomePage  from './pages/HomePage';
import SinupPage  from './pages/SinupPage';
import LoginPage  from './pages/LoginPage';
import ForgotPage from './pages/ForgotPage';
import ProgramCoordinatorCrud from './pages/ProgramCoordinatorCrud';
import Logout from './pages/Logout';
import Assessments from './pages/Assessments';
import PC from './pages/PC';
//import InstructorCourse from './pages/InstructorCourse';
import Adduser from './pages/addUser';
import Addcourse from './pages/addCourse'
import ReportPage from './components/Report';
import  ChatComponent from  './components/chat';
//import studentPerformance from './pages/InstructorStudentPerformanceMonitoring';

function App() {
  

  return (
      <div className="App">
          <Router>
              
              <Routes>
                  <Route exact path="/" element={<LoginPage />} />
              </Routes>
              <Routes>
                  <Route exact path="/chat" element={<ChatComponent />} />
              </Routes>
              <Routes>
                  <Route exact path="/changepass" element={<Changepassword />} />
              </Routes>
              <Routes>
                  <Route exact path="/report" element={<ReportPage />} />
              </Routes>
              <Routes>
                  <Route exact path="/forgot" element={<ForgotPage />} />
              </Routes>
			  
              <Routes>
                  <Route exact path="/sinup" element={<SinupPage />} />
              </Routes>
              <Routes>
                  <Route exact path="/student" element={<StudentPage />} />
              </Routes>
              <Routes>
                  <Route exact path="/profile" element={<StudentProfile />} />
              </Routes>
              <Routes>
                  <Route exact path="/instructor" element={<InstructorDashboard />} />
              </Routes>
              <Routes>
                  <Route exact path="/instructorProf" element={<InstructorProfile />} />
              </Routes>
              <Routes>
                  <Route exact path="/services" element={<ServicesComponent />} />
              </Routes>
              <Routes>
                  <Route exact path="/aboutus" element={<AboutUs />} />
              </Routes>
              <Routes>
                  <Route exact path="/qa" element={<QualityAssuranceOfficerDashboard />} />
              </Routes>
              <Routes>
                  <Route exact path="/contact_us" element={<ContactUs />} />
              </Routes>
              <Routes>
                  <Route exact path="/addcourse" element={<Addcourse />} />
              </Routes>
              
              <Routes>
                  <Route exact path="/pc" element={<ProgramCordinator />} />
              </Routes>
              <Routes>
                  <Route exact path="/communication" element={<Communication />} />
              </Routes>
              <Routes>
                  <Route exact path="/adduser" element={<Adduser />} />
              </Routes>
              <Routes>
                  <Route exact path="/pointOfContact" element={<PointOfContact />} />
              </Routes>
              <Routes>
                  <Route exact path="/admin" element={<AdminDashboard />} />
              </Routes>
              <Routes>
                  <Route exact path="/message" element={<Message />} />
              </Routes>
              <Routes>
                  <Route exact path="/home" element={<HomePage />} />
              </Routes>
              <Routes>
                  <Route exact path="/pcc" element={<ProgramCoordinatorCrud />} />
              </Routes>
              <Routes>
                  <Route exact path="/logout" element={<Logout />} />
              </Routes>
              <Routes>
                  <Route exact path="/program" element={<PC />} />
              </Routes>
              <Routes>
                  <Route exact path="/assessments" element={<Assessments />} />
              </Routes>
              <Routes>
                  <Route exact path="/charts" element={<Charts />} />
              </Routes>
              

          </Router>
      </div>
  );
}

export default App;
